import { useState, useCallback } from "react";
import {
	GoogleReCaptchaProvider,
	useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Swal from "sweetalert2";

// Material UI y estilos
import {
	Box,
	Container,
	Typography,
	TextField,
	Icon,
	Card,
	InputAdornment,
	IconButton,
	Button,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@src/theme/index";
import "@pages/auth/LoginStyle.css";

// Componentes
import CustomTable from "@components/Tables/CustomTable";

// Utils
import { RECAPTCHA_SITE_KEY } from "@utils/global";

const CoalicionesPage = () => {
	return (
		<ThemeProvider theme={theme}>
			<Box
				className="section-image"
				sx={{ backgroundImage: `url(${require("@assets/img/back02.png")})` }}
			></Box>
		</ThemeProvider>
	);
};

const Chilpancingo = () => {
	return (
		<>
			<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
				<CoalicionesPage />
			</GoogleReCaptchaProvider>
		</>
	);
};

export default Chilpancingo;
