import { useRoutes } from "react-router-dom";
import { Routes } from "./routes";
import { ProtectedRoute, AlreadyLoggedIn } from "@middlewares";

import WebCamera from "@components/Compromisos/WebCamera";
import Welcome from "@pages/Welcome/Welcome";
import NotFound from "@pages/auth/NotFound";
import Coaliciones from "@pages/auth/Coaliciones";
import Chilpancingo from "@pages/auth/Chilpancingo";

const Router = () => {
	const routes = useRoutes([
		{
			path: "/",
			element: <AlreadyLoggedIn />,
		},
		{
			path: "/welcome",
			element: (
				<ProtectedRoute>
					<Welcome />
				</ProtectedRoute>
			),
		},
		{
			path: "/fotoine",
			element: (
				<ProtectedRoute>
					<WebCamera />
				</ProtectedRoute>
			),
		},
		{
			path: "/coaliciones2024",
			element: <Coaliciones />,
		},
		{
			path: "/chilpancingo",
			element: <Chilpancingo />,
		},
		{
			path: "*",
			element: <NotFound />,
		},
		...Routes,
	]);

	return routes;
};

export default Router;
