import React from "react";

import {
	Box,
	TextField,
	Chip,
	Icon,
	Checkbox,
	FormControlLabel,
} from "@mui/material";
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";

import { isEmptyOrInvalidString } from "@utils/validations";

const PersonalInformationForm = (props) => {
	const { errors, touched, values, handleChange } = props;

	return (
		<Box sx={{ mt: 1, mb: 2 }}>
			<Grid2 xs={12} sm={12} md={12} lg={12} display="flex">
				<Chip icon={<Icon>person</Icon>} label="Favor de confirmar sus datos" />
			</Grid2>

			<Grid2 container spacing={3} sx={{ mt: 2 }}>
				<Grid2 xs={12} sm={12} md={12} lg={12}>
					<span>
						<strong>{values.Nombre.toUpperCase()}</strong>
					</span>
				</Grid2>
			</Grid2>
			<Grid2 container spacing={3} sx={{ mt: 2 }}>
				<Grid2 xs={12} sm={12} md={3} lg={3}>
					<TextField
						error={touched.Celular && !isEmptyOrInvalidString(errors.Celular)}
						helperText={touched.Celular && errors.Celular && errors.Celular}
						sx={{ width: "100%" }}
						required
						label="Celular"
						type="text"
						name="Celular"
						onChange={handleChange}
						onBlur={(e) => {
							handleChange({
								target: {
									name: e.target.name,
									value: e.target.value.trim(),
								},
							});
						}}
						value={values.Celular}
						variant="outlined"
						size="small"
						className="fixed-input"
					/>
				</Grid2>
				<Grid2 xs={12} sm={12} md={3} lg={3}>
					<TextField
						error={touched.Correo && !isEmptyOrInvalidString(errors.Correo)}
						helperText={touched.Correo && errors.Correo && errors.Correo}
						sx={{ width: "100%" }}
						label="Correo"
						type="text"
						name="Correo"
						onChange={handleChange}
						onBlur={(e) => {
							handleChange({
								target: {
									name: e.target.name,
									value: e.target.value.trim(),
								},
							});
						}}
						value={values.Correo}
						variant="outlined"
						size="small"
						className="fixed-input"
					/>
				</Grid2>
			</Grid2>
			<Grid2 container spacing={3} sx={{ mt: 2 }}>
				<Grid2 xs={12} sm={12} md={3} lg={3}>
					<FormControlLabel
						checked={values.LlevaAcompaniante === 1 ? true : false}
						onChange={() =>
							handleChange({
								target: {
									name: "LlevaAcompaniante",
									value: values.LlevaAcompaniante === 1 ? 0 : 1,
								},
							})
						}
						control={
							<Checkbox
								icon={<CheckBoxOutlineBlank />}
								checkedIcon={<CheckBox />}
							/>
						}
						label="Llevará acompañante"
					/>
				</Grid2>
			</Grid2>
			{values.LlevaAcompaniante === 1 ? (
				<Grid2 container spacing={3} sx={{ mt: 2 }}>
					<Grid2 xs={12} sm={12} md={3} lg={3}>
						<TextField
							error={
								touched.NombreAcompanante &&
								!isEmptyOrInvalidString(errors.NombreAcompanante)
							}
							helperText={
								touched.NombreAcompanante &&
								errors.NombreAcompanante &&
								errors.NombreAcompanante
							}
							sx={{ width: "100%" }}
							required
							label="Nombre acompañante"
							type="text"
							name="NombreAcompanante"
							onChange={handleChange}
							onBlur={(e) => {
								handleChange({
									target: {
										name: e.target.name,
										value: e.target.value.trim(),
									},
								});
							}}
							value={values.NombreAcompanante}
							variant="outlined"
							size="small"
							className="fixed-input"
						/>
					</Grid2>
					<Grid2 xs={12} sm={12} md={3} lg={3}>
						<TextField
							error={
								touched.CelularAcompanante &&
								!isEmptyOrInvalidString(errors.CelularAcompanante)
							}
							helperText={
								touched.CelularAcompanante &&
								errors.CelularAcompanante &&
								errors.CelularAcompanante
							}
							sx={{ width: "100%" }}
							required
							label="Celular acompañante"
							type="text"
							name="CelularAcompanante"
							onChange={handleChange}
							onBlur={(e) => {
								handleChange({
									target: {
										name: e.target.name,
										value: e.target.value.trim(),
									},
								});
							}}
							value={values.CelularAcompanante}
							variant="outlined"
							size="small"
							className="fixed-input"
						/>
					</Grid2>
				</Grid2>
			) : null}
		</Box>
	);
};

export default PersonalInformationForm;
