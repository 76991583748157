import Yup from "@utils/Yupi18n";

export const InvitadosSchemas = Yup.object({
	Nombre: Yup.string().min(3).optional().label("Nombre"),
	Asistira: Yup.number().optional().label("Municipio"),
	Celular: Yup.string()
		.phone("Ingrese un número de teléfono válido")
		.required()
		.nullable(true)
		.min(10)
		.max(10),
	Correo: Yup.string()
		.email("Ingrese un correo electrónico válido")
		.optional()
		.nullable(true),
	LlevaAcompaniante: Yup.number().required().label("Lleva Acompañante"),
	NombreAcompanante: Yup.string()
		.min(3)
		.optional()
		.label("Nombre")
		.when("LlevaAcompaniante", {
			is: true, // Cuando LlevaAcompaniante es true
			then: Yup.string()
				.required("Nombre del Acompañante es obligatorio")
				.min(3), // Hace que sea obligatorio
			otherwise: Yup.string().optional(), // Opcional si LlevaAcompaniante es false
		}),
	CelularAcompanante: Yup.string()
		.phone("Ingrese un número de teléfono válido")
		.optional()
		.nullable(true)
		.min(10)
		.max(10)
		.when("LlevaAcompaniante", {
			is: true, // Cuando LlevaAcompaniante es true
			then: Yup.string()
				.required("Celular del Acompañante es obligatorio")
				.min(10)
				.max(10), // Hace que sea obligatorio
			otherwise: Yup.string().optional(), // Opcional si LlevaAcompaniante es false
		}),
});

export const InvitadosFilterSchema = Yup.object({
	actividad: Yup.string().optional(),
	Asistira: Yup.mixed().oneOf([null, 0, 1]).optional(),
});
