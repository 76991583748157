import { Public } from "@middlewares";
import Redirect from "../../pages/Tracking/Redirect";

const TrackingRoutes = [
	{
		path: "/tracking/:id",
		element: (
			<Public>
				<Redirect />
			</Public>
		),
	},
];

export default TrackingRoutes;
