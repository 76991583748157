import React, { useEffect, useState } from "react";
import AdminLayout from "@components/MainPage/AdminLayout";
import {
	Card,
	CardContent,
	Container,
	Button,
	Grid,
	Box,
	Icon,
	FormControlLabel,
	Switch,
} from "@mui/material";
import CustomTable from "@components/Tables/CustomTable";
import AdaptiveCard from "@components/Card/AdaptiveCard";
import CDEServices from "@services/CDEServices";
import Swal from "sweetalert2";
import Respuestas from "@components/CDE/Respuestas";
import FilterMecanismos from "@components/CDE/Filter";
import middleware from "@middlewares/middleware";
import Download from "@components/Downloads/Download";
import MessageAlert from "@components/Downloads/MessageAlert";

const ListMecanismos = () => {
	const [data, setData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [total, setTotal] = useState({});
	const [params, setParams] = useState({
		page: 0,
		pageSize: 10,
		filtered: [],
		sorted: [{ id: "encuesta2024.FechaCreo", value: "DESC" }],
	});
	const [paramTotal] = useState({
		page: 0,
		pageSize: 9999999999,
		filtered: [],
	});
	const [isLoading, setIsLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [showMore, setShowMore] = useState(false);
	const [dataClick, setDataClick] = useState({});
	const [isDownloading, setIsDownloading] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const [successDownload, setSuccessDownload] = useState(false);
	const handleClickOpen = (a, b, c, d) => {
		setDataClick(c);
		setOpen(true);
	};
	const handleClose = (value) => {
		setOpen(false);
	};
	const subColumnsNormal = [
		{ label: "", colspan: 1, align: "left" },
		{ label: "", colspan: 1, align: "left" },
		{ label: "", colspan: 1, align: "left" },
		{ label: "", colspan: 1, align: "left" },
		{ label: "", colspan: 1, align: "left" },
		{
			label:
				"Menciona dos motivos por el cual consideras tu calificación para la selección de candidaturas.",
			colspan: 2,
			align: "center",
		},
		{
			label: "Para evaluar el alcance de las propuestas y mensajes de campaña",
			colspan: 7,
			align: "center",
		},
		{ label: "", colspan: 1, align: "left" },
		{ label: "", colspan: 1, align: "left" },
		{ label: "", colspan: 1, align: "left" },
		{ label: "", colspan: 1, align: "left" },
		{ label: "", colspan: 1, align: "left" },
		{ label: "", colspan: 1, align: "left" },
		{ label: "", colspan: 1, align: "left" },
		{ label: "", colspan: 1, align: "left" },
		{
			label: "¿Cómo calificas tú trabajo en el Proceso Electoral 2023-2024?",
			colspan: 2,
			align: "center",
		},
		{
			label:
				"¿Qué factores consideras que influyeron en el resultado electoral?",
			colspan: 4,
			align: "center",
		},
		{ label: "", colspan: 1, align: "left" },
		{ label: "", colspan: 1, align: "left" },
	];
	const [columns, setColumns] = useState([
		//{ id: "row1", label: "Numeros", type: "number", fixed: 2 },
		{ id: "Municipio", label: "Municipio", type: "text" },
		{ id: "Nombre", label: "Nombre", type: "text" },
		{ id: "Celular", label: "Celular", type: "text" },
		{ id: "Militante", label: "Militante", type: "text" },
		{
			id: "respuestas",
			label: "Respuestas",
			type: "iconButton",
			color: "#0524eb",
			disablePadding: true,
			sort: false,
			onClick: handleClickOpen,
		},
		{ id: "FechaCreo", label: "Fecha", type: "text" },
	]);

	useEffect(() => {
		getData();
		getTotales();
	}, [params]);

	const getData = async () => {
		try {
			const res = await CDEServices.getMecanismos(params);
			const { results, response } = res;
			if (results) {
				let data = response.data.map((item) => {
					return {
						...item,
						Nombre:
							`${item.Nombre} ${item.Paterno} ${item.Materno}`.toUpperCase(),
						respuestas: "forum",
					};
				});
				setData(data);
				setDataTotal(response.total);
			} else {
				setData([]);
				setDataTotal(0);
			}
		} catch (error) {
			Swal.fire({ title: error.message, icon: "warning" });
		}
	};

	const getTotales = async () => {
		try {
			setIsLoading(true);
			const res = await CDEServices.getTotales(paramTotal);
			const { results, response } = res;
			if (results) {
				let data = response.data;
				setTotal(data);
			} else {
				setTotal({ EncustasUnicas: 0, TotalEncuestas: 0 });
			}
			setIsLoading(false);
		} catch (error) {
			Swal.fire({ title: error.message, icon: "warning" });
			setIsLoading(false);
		}
	};

	const handlePagination = ({ page, pageSize }) => {
		const paramstmp = {
			...params,
			page: page,
			pageSize: pageSize,
		};
		setParams(paramstmp);
	};

	const handleSort = (e) => {
		/*       setOrderBy(e.orderBy);
        setOrder(e.order); */

		const paramsNew = {
			...params,
			sorted: [{ id: e.orderBy, value: e.order }],
		};
		setParams(paramsNew);
	};

	const handleExport = () => {
		setIsDownloading(true);
		CDEServices.exportMecanismos({
			page: 0,
			pageSize: 99999999999999,
			filtered: params.filtered,
		})
			.then((res) => {
				if (res.success) {
					setShowMessage(true);
					setSuccessDownload(true);
				} else {
					setShowMessage(true);
					setSuccessDownload(false);
				}
			})
			.catch((error) => {
				setShowMessage(true);
				setSuccessDownload(false);
			})
			.finally(() => setIsDownloading(false));
	};

	const handleMore = (e) => {
		setShowMore(e);
		if (e) {
			setColumns([
				//{ id: "row1", label: "Numeros", type: "number", fixed: 2 },
				{ id: "Municipio", label: "Municipio", type: "text" },
				{ id: "Militante", label: "Militante", type: "text" },
				{ id: "Nombre", label: "Nombre", type: "text" },
				{ id: "Celular", label: "Celular", type: "text" },
				{
					id: "Pregunta1",
					fixed: 2,
					label:
						"Del método de selección que fue acordado conforme a la normatividad interna del PAN, ¿Con que calificación lo evalúas?",
					type: "text",
				},
				{ id: "Motivo1", label: "Motivo 1", type: "text" },
				{ id: "Motivo2", label: "Motivo 2", type: "text" },
				{ id: "LemaEstatal", label: "Estatal", type: "text" },
				{ id: "LemaNacional", label: "Nacional", type: "text" },
				{ id: "PropuestaAyuntamiento", label: "Ayuntamiento", type: "text" },
				{ id: "PropuestaGobernatura", label: "Gobernador", type: "text" },
				{ id: "PropuestaDLOC", label: "DLOC", type: "text" },
				{ id: "PropuestaDFED", label: "DFED", type: "text" },
				{
					id: "PropuestaSenadoPresidencia",
					label: "Senado-Presidencia",
					type: "text",
				},
				{
					id: "Pregunta4",
					label:
						"¿Cómo calificas el trabajo de campaña de tú Comité Directivo Estatal?",
					type: "text",
				},
				{
					id: "Pregunta5",
					label:
						"¿Cómo calificas el trabajo de campaña de tú Comité Directivo Municipal?",
					type: "text",
				},
				{
					id: "Pregunta6",
					label:
						"¿Cómo calificas el trabajo de quien encabezó la candidatura del PAN a la Gubernatura?",
					type: "text",
				},
				{
					id: "Pregunta7",
					label:
						"¿Cómo calificas el trabajo de quien encabezó la candidatura del PAN al Ayuntamiento de tu municipio?",
					type: "text",
				},
				{
					id: "Pregunta8",
					label:
						"¿Cómo calificas el trabajo de quien encabezó la candidatura del PAN a la diputación local de tu municipio?",
					type: "text",
				},
				{
					id: "Pregunta9",
					label:
						"¿Cómo calificas el trabajo de quien encabezó la candidatura del PAN a la diputación federal de tu municipio?",
					type: "text",
				},
				{
					id: "Pregunta10",
					label:
						"¿Cómo calificas el trabajo de quien encabezó la candidatura del PAN al Presidencia de la República?",
					type: "text",
				},
				{
					id: "Pregunta11",
					label:
						"¿Cómo calificas el trabajo de quienes encabezaron la candidatura del PAN al Senado?",
					type: "text",
				},
				{ id: "Pregunta12", label: "Calificación", type: "text" },
				{
					id: "Pregunta12option",
					label: " ¿En qué consistió tú participación electoral?",
					type: "text",
				},
				{
					id: "Pregunta13a",
					label: "Acciones del Gobierno Municipal",
					type: "text",
				},
				{
					id: "Pregunta13b",
					label: "Acciones del Gobierno Estatal",
					type: "text",
				},
				{
					id: "Pregunta13c",
					label: "Acciones del Gobierno Federal",
					type: "text",
				},
				{ id: "Pregunta13d", label: "Estructuras Electorales", type: "text" },
				{
					id: "AportacionGeneral",
					label: "CONCIDERACIÓN ADICIONAL O PROPUESTA DE MEJORA",
					type: "text",
				},
				{ id: "FechaCreo", label: "Fecha", type: "text" },
			]);
		} else {
			setColumns([
				//{ id: "row1", label: "Numeros", type: "number", fixed: 2 },
				{ id: "Municipio", label: "Municipio", type: "text" },
				{ id: "Nombre", label: "Nombre", type: "text" },
				{ id: "Celular", label: "Celular", type: "text" },
				{ id: "Militante", label: "Militante", type: "text" },
				{
					id: "respuestas",
					label: "Respuestas",
					type: "iconButton",
					color: "#0524eb",
					disablePadding: true,
					sort: false,
					onClick: handleClickOpen,
				},
			]);
		}
	};

	return (
		<AdminLayout>
			<Container maxWidth="false">
				<Respuestas open={open} onClose={handleClose} data={dataClick} />
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<AdaptiveCard
							title="Encuestas"
							icon="people"
							rightConfig={{ title: "Únicas", data: total.EncustasUnicas }}
							leftConfig={{ title: "Total", data: total.TotalEncuestas }}
							fixed
							adjust
						/>
					</Grid>

					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<FilterMecanismos
											setFiltros={(e) =>
												setParams({ ...params, page: 0, filtered: e })
											}
										/>
									</Grid>
									<Grid item xs={12}>
										{showMessage && (
											<Box marginTop={2}>
												<MessageAlert
													showMessage={showMessage}
													successDownload={successDownload}
													setShowMessage={setShowMessage}
												/>
											</Box>
										)}
										{isDownloading && (
											<Download isDownload={isDownloading} format="xlsx" />
										)}
									</Grid>
									<Grid item xs={12}>
										<Box
											display="flex"
											alignItems="center"
											justifyContent="space-between"
										>
											<FormControlLabel
												control={
													<Switch
														checked={showMore}
														onChange={(e) => handleMore(e.target.checked)}
													/>
												}
												label="Ver Respuestas"
											/>
											{middleware.checkMenuActionId("Exportar") && (
												<>
													{isDownloading ? (
														<></>
													) : (
														<Button
															variant="outlined"
															color="primaryDark"
															startIcon={<Icon>download</Icon>}
															size="small"
															onClick={handleExport}
															sx={{ borderRadius: "10px" }}
														>
															Exportar
														</Button>
													)}
												</>
											)}
										</Box>
									</Grid>

									<Grid item xs={12}>
										<CustomTable
											rows={data}
											columns={columns}
											subColumns={showMore ? subColumnsNormal : []}
											total={dataTotal}
											page={params.page}
											pageSize={params.pageSize}
											isLoading={isLoading}
											handlePagination={handlePagination}
											handleSort={handleSort}
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</AdminLayout>
	);
};
export default ListMecanismos;
