const InvitadosInterfaces = {
	UUID: "",
	Nombre: "",
	Celular: "",
	Correo: "",
	Asistira: 1,
	LlevaAcompaniante: 0,
	NombreAcompanante: "",
	CelularAcompanante: "",
	idEstatus: 1,
};

const InvitadosFilter = {
	actividad: "",
	Asistira: null,
};

export { InvitadosInterfaces, InvitadosFilter };
