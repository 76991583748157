import { Public, ProtectedRoute, PrivilegedRoute } from "@middlewares";

import EventosPaseLista from "@pages/Invitados/EventosPaseLista";
import EventosPaseListaInfo from "@pages/Invitados/EventosPaseListaInfo";
import QRAsistencia from "@pages/Invitados/QRAsistencia";

const InvitadosRoutes = [
	{
		path: "/eventos/mis-eventos",
		element: (
			<PrivilegedRoute>
				<EventosPaseLista id="EventosPaseLista" />
			</PrivilegedRoute>
		),
	},

	{
		path: "/eventos/mis-eventos/:id",
		element: (
			<ProtectedRoute>
				<EventosPaseListaInfo />
			</ProtectedRoute>
		),
	},

	{
		path: "/eventos/mis-eventosQR/:id",
		element: (
			<Public>
				<QRAsistencia />
			</Public>
		),
	},
];

export default InvitadosRoutes;
