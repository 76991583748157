import React, { useState } from "react";
import {
	Button,
	Card,
	Container,
	Grid,
	Icon,
	Typography,
	Stack,
	TextField,
} from "@mui/material";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//Components
import InputSelect from "@components/Selects/BasicSelect";
import { useFormik } from "formik";
import PropTypes from "prop-types";

import { InvitadosFilter } from "@data/interfaces/InvitadosInterfaces";
import { InvitadosFilterSchema } from "@data/schemas/InvitadosSchemas";

const PaseListaFilter = ({ onChangeFilter }) => {
	const formik = useFormik({
		initialValues: InvitadosFilter,
		validationSchema: InvitadosFilterSchema,
		onSubmit: (values) => handleFilter(values),
	});

	const [filter, setFilter] = useState({
		Asistira: null,
		actividad: "",
	});

	const handleChangeAsistira = (e) => {
		setFilter({
			...filter,
			Asistira: e.target.value,
		});
	};

	const handleFilter = ({ actividad }) => {
		let filtered = [];

		// if (filter.Asistira === 2) {
		// 	filtered.push({
		// 		id: "pablo_confirmaciones.Asistira",
		// 		filter: "is",
		// 		value: "null",
		// 	});
		// }

		// if (filter.Asistira === 1) {
		// 	filtered.push({
		// 		id: "pablo_confirmaciones.Asistira",
		// 		filter: "=",
		// 		value: 1,
		// 	});
		// }

		if (formik.values.actividad !== "") {
			filtered.push({
				id: "c.CUIP",
				filter: "LIKE",
				value: actividad,
			});
		}

		onChangeFilter(filtered);
	};

	const handleOnKeyPress = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			formik.submitForm();
		}
	};

	const handleClearFilter = () => {
		formik.setValues({ ...formik, actividad: "", Asistira: null });
		setFilter({ Asistira: null, actividad: "" });
		onChangeFilter([]);
	};

	return (
		<Card className="card-primary">
			<Container maxWidth={false} sx={{ p: 2 }}>
				<Stack direction="row" spacing={1} marginBottom={2} alignItems="center">
					<Icon>tune</Icon>
					<Typography variant="subtitle1" fontWeight={600}>
						Filtros
					</Typography>
				</Stack>

				<Grid container spacing={2}>
					<Grid item xs={12} sm={7} md={6} lg={6}>
						<TextField
							sx={{ width: "100%" }}
							label="CUIP"
							type="text"
							name="actividad"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							onKeyPress={handleOnKeyPress}
							value={formik.values.actividad.toUpperCase()}
							variant="outlined"
							size="small"
							className="fixed-input"
						/>
					</Grid>
					{/* <Grid item xs={12} md={3}>
						<InputSelect
							label="Confirmación"
							options={[
								{ value: -1, label: "Todos" },
								{ value: 1, label: "Si" },
								{ value: 2, label: "No confirmado" },
							]}
							name="groupBy"
							value={filter.Asistira}
							onChange={handleChangeAsistira}
							sx={{ width: "100%" }}
						/>
					</Grid> */}

					<Grid
						item
						xs={12}
						sm={5}
						md={6}
						lg={6}
						sx={{ display: "flex", gap: 1 }}
					>
						<Button
							variant="contained"
							color="primaryDark"
							onClick={formik.submitForm}
						>
							Filtrar
						</Button>
						<Button
							variant="text"
							color="primaryDark"
							onClick={handleClearFilter}
							sx={{ ml: 1 }}
						>
							Limpiar
						</Button>
					</Grid>
				</Grid>
			</Container>
		</Card>
	);
};

PaseListaFilter.propTypes = {
	onChangeFilter: PropTypes.func.isRequired,
};

export default PaseListaFilter;
