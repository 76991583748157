import { useState, useEffect, useCallback } from "react";
import { Swal } from "@utils/alerts";

// Material UI
import {
	Box,
	Card,
	CardContent,
	Container,
	ListItemText,
	Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import Filter from "@components/Invitaciones/PaseListaFilter";
import CustomTable from "@components/Tables/CustomTable";

// Servicios
import SurveyServices from "@services/SurveyServices";

// Utils
import { isEmptyOrNullObject } from "@utils/validations";

const Surveys = () => {
	const filter = [];

	const colums = [
		{ label: "Fecha Creo", id: "FechaCreo" },
		{ label: "CUIP", id: "CUIP", align: "left" },
		{ label: "UsuarioCreo", id: "UsuarioCreo", align: "center" },
		{ label: "Estatus", id: "idEstatus" },
	];

	const [loading, setLoading] = useState(true);

	const [data, setData] = useState({
		page: 0,
		pageSize: 25,
		filtered: filter,
		sorted: [{ id: "c.FechaCreo", value: "desc" }],
	});
	const [dataListEvents, setDataListEvents] = useState([]);
	const [total, setTotal] = useState(0);

	const getEventosData = useCallback(async (data) => {
		try {
			setLoading(true);
			console.log("=>data: ", JSON.stringify(data));

			const result = await SurveyServices.getSurvey(data);

			console.log("=>result: ", result);
			const { results, response, message } = result;

			if (results) {
				if (response.data.length) {
					const Eventos = response.data.map((item) => ({
						...item,
						idEstatus: (
							<ListItemText
								primary={
									<Typography variant="div" fontWeight={700} fontSize={15}>
										{item.idEstatus === 1 ? "Activo" : ""}
									</Typography>
								}
							/>
						),
						// Actividad: (
						// 	<Button
						// 		disableElevation
						// 		variant="text"
						// 		color="primaryDark"
						// 		size="small"
						// 		sx={{
						// 			"&:hover": { bgcolor: blue[900], color: "white" },
						// 			p: "0 auto",
						// 			textAlign: "left",
						// 		}}
						// 		startIcon={<Icon>edit</Icon>}
						// 		onClick={() => handlePaseLista(item)}
						// 		// disabled={
						// 		// 	!isSameDay(new Date(item.FechaFin + " 00:00:00"), new Date())
						// 		// }
						// 	>
						// 		<ListItemText
						// 			primary={
						// 				<Typography variant="div" fontWeight={700} fontSize={15}>
						// 					{item.Nombre}
						// 				</Typography>
						// 			}
						// 		/>
						// 	</Button>
						// ),
					}));
					setDataListEvents(Eventos);
					setTotal(response.total);
				} else {
					setDataListEvents([]);
					setTotal(0);
				}
			} else throw new Error(message);
		} catch (e) {
			Swal.fire({ title: e.message, icon: "warning" });
		} finally {
			setLoading(false);
		} // eslint-disable-next-line
	}, []);

	useEffect(() => {
		getEventosData(data); // eslint-disable-next-line
	}, [data]);

	const handleFilter = (filtered) =>
		setData((prev) => ({ ...prev, filtered: filter.concat(filtered) }));

	const handlePagination = (pagination) => setData({ ...data, ...pagination });

	const handleSort = (sort) => {
		const fields = {
			Actividad: "c.FechaCreo",
		};

		let sorted = [];
		if (!isEmptyOrNullObject(sort)) {
			sorted.push({ id: fields[sort.orderBy], value: sort.order });
		}

		if (total > 0) setData({ ...data, sorted });
	};

	return (
		<AdminLayout key={1}>
			<Container maxWidth={false} sx={{ backgroundColor: grey }}>
				<ActionHeader title="Relación de cuestionarios realizados" />

				<Box>
					<Filter onChangeFilter={handleFilter} />
					<Card className="card-primary">
						<CardContent>
							<CustomTable
								rows={dataListEvents}
								columns={colums}
								handlePagination={handlePagination}
								isLoading={loading}
								total={total}
								page={data.page}
								pageSize={data.pageSize}
								stickyHeader={true}
								handleSort={handleSort}
							/>
						</CardContent>
					</Card>
				</Box>
			</Container>
		</AdminLayout>
	);
};

export default Surveys;
