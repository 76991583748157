import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Yup from "@utils/Yupi18n";
import Swal from "sweetalert2";

// Material UI
import {
	Box,
	Container,
	Typography,
	TextField,
	Icon,
	Card,
	InputAdornment,
	IconButton,
	Button,
	CircularProgress,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@src/theme/index";

import { setVars, RECAPTCHA_SITE_KEY } from "@utils/global";
import { isEmptyOrInvalidString } from "@utils/validations";

import avatar from "@assets/img/SIAN.png";
// import gorro from "@assets/img/GorroNavidad.png";
// import Snowfall from "react-snowfall";
import "@pages/auth/LoginStyle.css";

// import ReCAPTCHA from "react-google-recaptcha";
import {
	GoogleReCaptchaProvider,
	useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

// Servicios
import AuthServices from "@services/AuthServices";

// Hooks
import useWindowDimensions from "@hooks/useWindowDimensions";

const CardHeader = () => {
	return (
		<>
			<div className="card-header-multimedia">
				<img alt="Logo" src={avatar} width={120} height={120} />
				<Typography
					sx={{ textAlign: "center", mt: 2 }}
					variant="h5"
					fontWeight={400}
				>
					Iniciar Sesión
				</Typography>
			</div>
		</>
	);
};

const CardBody = ({
	showPassword,
	setShowPassword,
	formik,
	handleOnKeyPress,
	// captchaRef,
	// onChangeCaptcha,
}) => {
	return (
		<>
			<div className="card-content">
				<TextField
					//label="Usuario"
					placeholder="Usuario"
					className="input"
					size="small"
					sx={{ mb: 1 }}
					error={
						formik.touched.username &&
						!isEmptyOrInvalidString(formik.errors.username)
					}
					helperText={
						formik.touched.username &&
						formik.errors.username &&
						formik.errors.username
					}
					type="text"
					name="username"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.username}
					autoFocus
					onKeyPress={handleOnKeyPress}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<IconButton sx={{ cursor: "inherit" }}>
									<Icon>account_circle</Icon>
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				<TextField
					//label="Contraseña"
					placeholder="Contraseña"
					className="input"
					size="small"
					error={
						formik.touched.password &&
						!isEmptyOrInvalidString(formik.errors.password)
					}
					helperText={
						formik.touched.password &&
						formik.errors.password &&
						formik.errors.password
					}
					type={showPassword ? "text" : "password"}
					name="password"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.password}
					onKeyPress={handleOnKeyPress}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<IconButton
									aria-label="toggle password visibility"
									onClick={(e) => {
										setShowPassword(!showPassword);
									}}
								>
									{showPassword ? (
										<Icon>visibility_off</Icon>
									) : (
										<Icon>visibility</Icon>
									)}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>

				<Typography
					variant="body2"
					sx={{ textAlign: "center", color: "#9a9a9a" }}
				>
					Acceso a personal autorizado
				</Typography>
			</div>
		</>
	);
};

const CardAction = ({ signIn, loading, formik }) => {
	return (
		<>
			<div className="card-action">
				{loading ? (
					<CircularProgress
						sx={{
							display: "flex",
							margin: "0 auto",
							width: "30px !important",
							height: "30px !important",
						}}
					/>
				) : (
					<Button className="button-login" onClick={signIn}>
						INICIAR SESIÓN
					</Button>
				)}
			</div>
		</>
	);
};

const Login = () => {
	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const { executeRecaptcha } = useGoogleReCaptcha();
	// const captchaRef = useRef(null);
	const navigate = useNavigate();

	const { width } = useWindowDimensions();
	const size = { xxs: width < 400 };

	const initialValues = {
		username: "",
		password: "",
		type_login: "web",
	};

	const validationSchema = Yup.object({
		username: Yup.string().required().label("Usuario"),
		password: Yup.string().required().min(6).label("Contraseña"),
	});

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			//signIn(values);
			onChangeCaptcha(values);
		},
	});

	const handleOnKeyPress = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			formik.submitForm();
		}
	};

	const onChangeCaptcha = useCallback(
		(values) => {
			if (!executeRecaptcha) {
				return;
			}

			executeRecaptcha("login")
				.then((token) => {
					const data = {
						...values,
						token: token,
					};
					signIn(data);
				})
				.catch((error) => Swal.fire({ title: error, icon: "warning" }));
		},
		// eslint-disable-next-line
		[executeRecaptcha]
	);

	const signIn = async (values) => {
		// const token = captchaRef.current.getValue()

		console.log("=>signIn: ", JSON.stringify(values));

		try {
			setLoading(true);

			const result = await AuthServices.login(values);
			const { results, response, message } = await result;

			console.log("=>result Login: ", result);

			if (results) {
				//if (response.forcePasswordChange) return forcePasswordChange();
				//console.log("=>save: ", response);
				setVars("Token", response);

				navigate(response.user.DefaultPage);
			} else throw new Error(message);
		} catch (e) {
			console.log("=>e: ", e);
			Swal.fire({ title: e.message, icon: "warning" });
		} finally {
			setLoading(false);
			// captchaRef.current.reset();
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<Box
				className="section-image"
				sx={{
					backgroundImage: `url(${require("@assets/img/back02.png")})`,
					py: { xs: 0 },
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{/*    <Snowfall snowflakeCount={100} fallSpeed={0.75} snowflakeSize={3} /> */}
				<Container component="main" maxWidth="sm">
					<Box className="login-container" sx={{ position: "relative" }}>
						<Card
							className="card-login"
							sx={{ width: { xs: size.xxs ? "90%" : "80%", sm: "70%" } }}
						>
							{/*     <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  transform: "translate(30%, -50%) rotate(12deg)",
                }}
              >
                <img
                  alt="Logo"
                  src={gorro}
                  width={"200"}
                  height={"200"}
                  style={{
                    position: "relative",
                    right: "25%",
                  }}
                />
              </Box> */}
							<CardHeader />
							<CardBody
								showPassword={showPassword}
								setShowPassword={setShowPassword}
								formik={formik}
								handleOnKeyPress={handleOnKeyPress}
							/>
							<CardAction
								signIn={formik.submitForm}
								loading={loading}
								formik={formik}
							/>
						</Card>
					</Box>
				</Container>
			</Box>
		</ThemeProvider>
	);
};

const LoginSian = () => {
	return (
		<>
			<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
				<Login />
			</GoogleReCaptchaProvider>
		</>
	);
};

export default LoginSian;
