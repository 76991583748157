import { PrivilegedRoute } from "@middlewares";

import SurveyNew from "@pages/Invitados/SurveyNew";
import Surveys from "@pages/Invitados/Surveys";

const SurveyRoutes = [
	{
		path: "/survey-new",
		element: (
			<PrivilegedRoute>
				<SurveyNew />
			</PrivilegedRoute>
		),
	},
	{
		path: "/survey",
		element: (
			<PrivilegedRoute>
				<Surveys />
			</PrivilegedRoute>
		),
	},
];

export default SurveyRoutes;
