import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Swal } from "@utils/alerts";

// Material UI
import { Box, Container, CircularProgress } from "@mui/material";
import { grey } from "@mui/material/colors";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import ActionHeader from "@components/Containers/ActionHeader";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import QrReader from "@components/Containers/QrReader";
import CustomModal from "@components/Modal/CustomModal";
import PreguntasRespuestasEventos from "@components/Events/PreguntasRespuestasEventos";

// Servicios
import EventsServices from "@services/EventsServices";

const EventosPaseListaInfo = () => {
	const { id } = useParams();

	const [open, setOpen] = useState(false);

	const [loading, setLoading] = useState(true);
	const [loadingPaseLista, setLoadingPaseLista] = useState(false);
	const [loadingPreguntas, setLoadingPreguntas] = useState(false);

	const [ultimoScan, setUltimoScan] = useState({
		Nombre: "",
		Paterno: "",
		Materno: "",
		qr: "",
	});
	const [dataListEvents, setDataListEvents] = useState({
		id: null,
		Actividad: "",
	});

	const [preguntas, setPreguntas] = useState([]);
	const [qr, setQR] = useState(null);

	useEffect(() => {
		if (id) {
			const data = {
				page: 0,
				pageSize: 20,
				filtered: [{ id: "eventos.id", filter: "=", value: id }],
				sorted: [{ id: "eventos.FechaInicio", value: "asc" }],
			};

			getEventosData(data);
		} // eslint-disable-next-line
	}, [id]);

	useEffect(() => {
		if (dataListEvents?.tienePreguntasPA === 1) {
			getQuestions();
		} // eslint-disable-next-line
	}, [dataListEvents]);

	const getEventosData = useCallback(async (data) => {
		try {
			setLoading(true);

			const result = await EventsServices.getMyRecords(data);
			const { results, response, message } = result;

			if (results) setDataListEvents(response.data[0]);
			else throw new Error(message);
		} catch (e) {
			Swal.fire({ title: e.message, icon: "warning" });
		} finally {
			setLoading(false);
		} // eslint-disable-next-line
	}, []);

	const getQuestions = useCallback(async () => {
		setLoadingPreguntas(true);
		setPreguntas([]);

		const params = {
			page: 0,
			pageSize: 10,
			filtered: [
				{ id: "idEvento", filter: "=", value: id },
				{ id: "idTipoPregunta", filter: "=", value: 2 },
			],
		};

		try {
			const result = await EventsServices.getQuestions(params);
			const { results, response, message } = result;

			if (results) setPreguntas(response.data);
			else throw new Error(message);
		} catch (e) {
			Swal.fire({ title: e.message, icon: "warning" });
		} finally {
			setLoadingPreguntas(false);
		} // eslint-disable-next-line
	}, []);

	const setPaseLista = async (user, answers = []) => {
		setLoadingPaseLista(true);
		const data = { user, answers, idEvento: id };

		try {
			const result = await EventsServices.setAttendanceUsers(data);
			const { results, response, message } = result;

			if (results) {
				setUltimoScan({ ...response.data, qr: user });
				setQR(null);
				setOpen(false);
				Swal.fire({ title: message, icon: "success" });
			} else throw new Error(message);
		} catch (e) {
			Swal.fire({ title: e.message, icon: "warning" });
		} finally {
			setLoadingPaseLista(false);
		}
	};

	const handleQuest = async (user) => {
		if (dataListEvents.tienePreguntasPA === 1) {
			setOpen(true);
			setQR(user);
		} else setPaseLista(user);
	};

	const setAttendanceWithAnswers = async (values) => setPaseLista(qr, values);

	return (
		<AdminLayout key={id}>
			<Container maxWidth={false} sx={{ backgroundColor: grey }}>
				<ActionHeader title="Confirmación de asistencia" />

				<LoadingForm
					isLoading={loadingPaseLista}
					isOpen={loadingPaseLista}
					setIsOpen={() => {}}
					loadinMessage={"Pasando Asistencia..."}
				/>

				{loading ? (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							gap: 2,
						}}
					>
						<CircularProgress size={50} />
						Cargando...
					</Box>
				) : !dataListEvents?.id ? (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<h2 style={{ textAlign: "center" }}>
							No se encontró el evento, o no formas parte del staff. <br />{" "}
							<br /> Vuelve a intentarlo
						</h2>
					</Box>
				) : (
					<Box>
						<h2>{dataListEvents.Actividad}</h2>

						<h3>
							Último escaneado:{" "}
							{`${ultimoScan.Nombre} ${ultimoScan.Paterno} ${ultimoScan.Materno}`}
						</h3>

						<QrReader
							setResult={handleQuest}
							result={ultimoScan.qr}
							dontCloseOnScan
							verifyUUID
							verifyURL
						/>

						<CustomModal
							open={open}
							onClose={() => setOpen(false)}
							disableClose
							fullWidth
						>
							{loadingPreguntas ? (
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										gap: 2,
									}}
								>
									<CircularProgress size={50} />
									Cargando...
								</Box>
							) : (
								<PreguntasRespuestasEventos
									titulo={dataListEvents.Actividad}
									subtitulo="asistencia"
									preguntas={preguntas}
									handleChange={setAttendanceWithAnswers}
								/>
							)}
						</CustomModal>
					</Box>
				)}
			</Container>
		</AdminLayout>
	);
};

export default EventosPaseListaInfo;
