import { useParams } from "react-router-dom";
import { Grid, Typography, Button, Box, Container } from "@mui/material";
import Swal from "sweetalert2";
import avatar from "@assets/img/Chilpancingo.png";
import { QRCode } from "react-qrcode-logo";
import { useState, useEffect, useCallback } from "react";
import { handleAttrs } from "@utils/Utilities";
import PersonalInformationForm from "@components/Invitaciones/PersonalInformationForm";
import Grid2 from "@mui/material/Unstable_Grid2";
import LoadingForm from "@components/LinearProgress/LoadingForm";
import { InvitadosInterfaces } from "@data/interfaces/InvitadosInterfaces";
import { InvitadosSchemas } from "@data/schemas/InvitadosSchemas";
import { isEmptyOrNullObject } from "@utils/validations";
import { useFormik } from "formik";
import CDEServices from "@services/CDEServices";
import SendIcon from "@mui/icons-material/Send";
import { RECAPTCHA_SITE_KEY } from "@utils/global";
import {
	GoogleReCaptchaProvider,
	useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const RegisterTomaProtesta = () => {
	const { id } = useParams();
	const [isLoadigForm, setIsLoadingForm] = useState(false);
	const [isSuccessFormSave, setIsSuccessFormSave] = useState(false);
	const [openLinearProgress, setOpenLinearProgress] = useState(false);
	const [showQR, setShowQR] = useState({ show: false, Nombre: "", UUID: "" });
	const [load, setLoad] = useState(true);
	const { executeRecaptcha } = useGoogleReCaptcha();

	const formik = useFormik({
		initialValues: InvitadosInterfaces,
		validationSchema: InvitadosSchemas,
		onSubmit: (values) => {
			onChangeCaptcha(handleAttrs(values));
		},
	});

	useEffect(() => {
		if (id) {
			CDEServices.getConfirmInvitacion({
				filtered: [
					{
						id: "UUID",
						value: id,
						filter: "=",
					},
				],
			})
				.then((res) => {
					if (res.results && res.response.length > 0) {
						setShowQR({
							show: false,
							Nombre: res.response[0].Nombre,
							UUID: res.response[0].UUID,
						});
						formik.setValues({
							...formik.values,
							Cargo: res.response[0].Cargo,
							Nombre: res.response[0].Nombre,
							Celular: res.response[0].Celular,
							Correo: res.response[0].Correo,
							UUID: res.response[0].UUID,
							idEstatus: res.response[0].idEstatus,
						});
					} else {
						Swal.fire({
							title: "Lo sentimos!, no pudimos localizar la invitación.",
							icon: "warning",
							showConfirmButton: true,
							allowOutsideClick: true,
							allowEscapeKey: true,
						});
					}
				})
				.catch((error) => Swal.fire({ title: error.message, icon: "warning" }))
				.finally(() => setLoad(false));
		}
	}, [id]);

	const onChangeCaptcha = useCallback(
		(values) => {
			if (!executeRecaptcha) {
				return;
			}

			executeRecaptcha("invito")
				.then((token) => {
					const data = {
						...values,
						token: token,
					};
					handleVerification(handleAttrs(data));
				})
				.catch((error) => Swal.fire({ title: error, icon: "warning" }));
		},
		[executeRecaptcha]
	);

	const handleVerification = (values) => {
		if (!isEmptyOrNullObject(values)) {
			handleSave(values);
		}
	};

	const handleSave = (values) => {
		setIsLoadingForm(true);
		setOpenLinearProgress(true);
		setIsSuccessFormSave(false);

		let data = {};
		Object.keys(InvitadosInterfaces).forEach((key) => {
			data[key] = values[key] !== null ? values[key] : InvitadosInterfaces[key];
		});

		delete data.Nombre;
		delete data.idEstatus;

		CDEServices.setConfirmInvitacion(data)
			.then((res) => {
				if (res.results) {
					formik.setValues({
						...formik.values,
						idEstatus: 2,
						Nombre: data.Nombre,
					});
					setShowQR((prevShowQR) => ({
						...prevShowQR,
						show: true,
					}));
					Swal.fire({
						title: res.message,
						icon: "success",
						showConfirmButton: true,
						allowOutsideClick: true,
						allowEscapeKey: true,
					});
				} else {
					Swal.fire({
						title: "Error al confirmar asistencia",
						icon: "warning",
						showConfirmButton: true,
						allowOutsideClick: true,
						allowEscapeKey: true,
					});
				}
			})
			.catch((error) => Swal.fire({ title: error.message, icon: "warning" }))
			.finally(() => {
				setIsLoadingForm(false);
				setOpenLinearProgress(false);
			});
	};

	const handleSummit = (e) => {
		if (Object.values(formik.errors).length > 0) {
			Swal.fire({
				icon: "error",
				title: "Revisar",
				html: `<ul>${Object.values(formik.errors)
					.map((item) => `<li>${item}</li>`)
					.join("")}</ul>`,
			});
			return true;
		}

		formik.submitForm(formik.values);
	};

	if (load) {
		return (
			<LoadingForm
				loadinMessage={"Cargando datos..."}
				successMessage="¡Cargado con exito!"
				isLoading={isLoadigForm}
				success={isSuccessFormSave}
				isOpen={openLinearProgress}
				setIsOpen={() => setOpenLinearProgress(!openLinearProgress)}
			/>
		);
	}

	return (
		<Grid container direction="column" justifyContent="flex-end">
			<Grid item xs={12}>
				<Grid container justifyContent="center" alignItems="center">
					<Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
						{showQR.Nombre && !showQR.show ? (
							<Grid
								container
								spacing={2}
								alignItems="center"
								justifyContent="center"
							>
								<Grid item xs={12}>
									<Box
										sx={{
											bgcolor: "background.paper",
											pt: 8,
											pb: 6,
										}}
									>
										<Container
											maxWidth="lg"
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												justifyContent: "center",
											}}
										>
											<img
												alt="Logo"
												src={avatar}
												width={150}
												height={150}
												style={{ marginBottom: 10 }}
											/>
											<Typography
												variant="h5"
												align="justify"
												color="#16537E"
												paragraph
												sx={{ mt: 3 }}
											>
												Oficina de Relaciones Públicas del{" "}
												<strong>
													Gobierno Entrante de Chilpancingo de los Bravo
												</strong>
												, Guerrero
											</Typography>
										</Container>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Typography
										variant="h6"
										align="center"
										color="#16537E"
										gutterBottom
									>
										Confirmación para asistencia a la toma de protesta de{" "}
										<strong>Alejandro Arcos Catalán</strong>
										<br />
										Como Presidente Municipal Constitucional de Chilpancingo de
										los Bravo, Guerrero.
									</Typography>
								</Grid>
								<Grid item xs={12}>
									{formik.values.idEstatus !== 2 ? (
										<PersonalInformationForm
											loadigCatalogs={load}
											errors={formik.errors}
											touched={formik.touched}
											values={formik.values}
											setValues={formik.setValues}
											handleChange={formik.handleChange}
											NotSimple={true}
											validar={false}
										/>
									) : (
										<Grid
											container
											direction="column"
											alignItems="center"
											justifyContent="center"
										>
											<Grid2 container spacing={3} sx={{ mt: 2 }}>
												<Grid2 xs={12} sm={12} md={12} lg={12}>
													<span>
														<strong>{showQR.Nombre.toUpperCase()}</strong>
													</span>
												</Grid2>
											</Grid2>
											<QRCode value={showQR.UUID} />
										</Grid>
									)}
								</Grid>

								{formik.values.idEstatus === 1 ? (
									<Grid item xs={12}>
										<Grid
											item
											container
											direction="column"
											alignItems="center"
											xs={12}
										>
											<Button
												variant="contained"
												endIcon={<SendIcon />}
												onClick={handleSummit}
											>
												Confirmar Asistencia
											</Button>
										</Grid>
									</Grid>
								) : (
									<span style={{ fontWeight: "bold", color: "blue" }}>
										Asistencia confirmada
									</span>
								)}
							</Grid>
						) : !showQR.show ? (
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								sx={{ minHeight: "calc(100vh - 68px)" }}
							>
								<Typography
									variant="h5"
									align="justify"
									color="#16537E"
									paragraph
									sx={{ mt: 3 }}
								>
									Lo sentimos, no pudimos encontrar una invitación con su
									código.
									<br />
									<strong>
										Oficina de Relaciones Públicas del Gobierno Entrante de
										Chilpancingo de los Bravo
									</strong>
									, Guerrero
								</Typography>
							</Grid>
						) : (
							<Grid
								container
								direction="column"
								alignItems="center"
								justifyContent="center"
							>
								<Grid item xs={12}>
									<Box
										sx={{
											bgcolor: "background.paper",
											pt: 8,
											pb: 6,
										}}
									>
										<Container
											maxWidth="lg"
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												justifyContent: "center",
											}}
										>
											<img
												alt="Logo"
												src={avatar}
												width={150}
												height={150}
												style={{ marginBottom: 10 }}
											/>
											<Typography
												variant="h5"
												align="justify"
												color="#16537E"
												paragraph
												sx={{ mt: 3 }}
											>
												Oficina de Relaciones Públicas del{" "}
												<strong>
													Gobierno Entrante de Chilpancingo de los Bravo
												</strong>
												, Guerrero
											</Typography>
										</Container>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Typography
										variant="h6"
										align="center"
										color="#16537E"
										gutterBottom
									>
										Confirmación para asistencia a la toma de protesta de{" "}
										<strong>Alejandro Arcos Catalán</strong>
										<br />
										Como Presidente Municipal Constitucional de Chilpancingo de
										los Bravo, Guerrero.
									</Typography>
								</Grid>
								<Grid
									container
									direction="column"
									alignItems="center"
									justifyContent="center"
								>
									<Grid2 container spacing={3} sx={{ mt: 2 }}>
										<Grid2 xs={12} sm={12} md={12} lg={12}>
											<span>
												<strong>{showQR.Nombre.toUpperCase()}</strong>
											</span>
										</Grid2>
									</Grid2>
									<QRCode value={showQR.UUID} />
									<span style={{ fontWeight: "bold", color: "blue" }}>
										Asistencia confirmada
									</span>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sx={{ m: 3, mt: 1 }}>
				{/* <AuthFooter /> */}
			</Grid>
		</Grid>
	);
};

const recaptcha = () => {
	return (
		<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
			<RegisterTomaProtesta />
		</GoogleReCaptchaProvider>
	);
};

export default recaptcha;
