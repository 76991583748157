import React, { useState } from "react";
import {
	Box,
	Typography,
	Radio,
	RadioGroup,
	FormControlLabel,
	Checkbox,
	Button,
	Paper,
	Container,
	CircularProgress,
	TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Grid2 from "@mui/material/Unstable_Grid2";
import SurveyServices from "@services/SurveyServices";

const Preguntas = [
	{
		id: "1",
		type: "single",
		question: "¿Cuál es la función que desempeña actualmente?",
		options: [
			"De prevención reacción o de investigación en la Institución Policial(Policia Estatal)",
			"De investigación en la procuraduría General o Fiscalía(Policia Ministerial o equivalente)",
			"De Vigilancia o custodio del Sistema Penitenciario(Custodio)",
		],
		required: true,
	},
	{
		id: "2",
		type: "single",
		question: "SEXO",
		options: ["Masculino", "Femenino"],
		required: true,
	},
	{
		id: "3",
		type: "single",
		question: "EDAD ESPECIFIQUE",
		options: [
			"De 18 a 23 años",
			"De 24 a 29 años",
			"De 30 a 35 años",
			"De 36 a 41 años",
			"De 42 a 47 años",
			"De 48 a 53 años",
			"De 54 a 60 años",
			"Mayor de 60 años",
		],
		required: true,
	},
	{
		id: "4",
		type: "single",
		question: "SITUACIÓN PERSONAL",
		options: ["Casado(a)", "Divorciado(a)", "Unión libre", "Soltero(a)"],
		required: true,
	},
	{
		id: "5",
		type: "single",
		question: "ESCOLARIDAD (Último grado de estudios)",
		options: [
			"Preescolar",
			"Primaria",
			"Secundaria",
			"Carrera Técnica con Secundaria Terminada",
			"Normal Básica",
			"Preparatoria o bachillerato",
			"Carrera Técnica con preparatoria Terminada",
			"Licenciatura o Profesional",
			"Maestría o Doctorado",
			"Ninguno",
		],
		required: true,
	},
	{
		id: "6",
		type: "single",
		question:
			"EN SU TRABAJO, ¿Cuántas personas tiene bajo su mando o responsabilidad?",
		options: [
			"De 1 a 5",
			"De 6 a 10",
			"De 11 a 30",
			"De 31 en adelante",
			"Ninguna",
		],
		required: true,
	},
	{
		id: "7",
		type: "single",
		question:
			"ANTIGÜEDAD, ¿Cuánto tiempo tiene trabajando en su actual institución?",
		options: [
			"Menos de 2 años",
			"De 2 a 5 años",
			"De 6 a 10 años",
			"De 11 a 15 años",
			"De 16 a 20 años",
			"De 21 a 25 años",
			"De 26 a 30 años",
			"Más de 30 años",
		],
		required: true,
	},
	{
		id: "8",
		type: "single",
		question: "INGRESOS ¿A cuánto asciende su sueldo mensual bruto?",
		options: [
			"Menos de $1600 pesos",
			"De $1600 a $4800 pesos",
			"De $4801 a $8000 pesos",
			"De $8001 a $11200 pesos",
			"De $11201 a $16000 pesos",
			"De $16001 a $18000 pesos",
			"Más de $18000",
		],
		required: true,
	},
	{
		id: "9",
		type: "single",
		question:
			"DEPENDIENTES ¿Cuántas personas dependen de ese ingreso (incluyéndose)?",
		options: [
			"De 1 a 2",
			"De 3 a 4",
			"De 5 a 6",
			"De 7 a 8",
			"De 9 a 10",
			"Más de 10",
		],
		required: true,
	},

	{
		id: "10",
		type: "single",
		question:
			"INGRESO ADICIONAL ¿Cuenta con otros ingresos por actividad comercial, financiera o servicios profesionales?",
		options: ["Sí", "No"],
		nextQuestion: {
			No: "11",
		},
		required: true,
	},

	{
		id: "10.1",
		type: "single",
		question: "¿A cuánto asciende su ingreso adicional?",
		options: [
			"Menos de $1000 pesos",
			"De $1000 a $3000 pesos",
			"De $3001 a $5000 pesos",
			"De $5001 a $8000 pesos",
			"De $8001 a $15000 pesos",
			"De $15001 a $25000 pesos",
			"Más de $25000 pesos",
		],
		required: true,
	},

	{
		id: "11",
		type: "single",
		question: "¿Su Institución cuenta con Servicio Profesional de Carrera?",
		options: ["Sí", "No", "No sabe"],
		nextQuestion: {
			No: "12",
			"No sabe": "12",
		},
		required: true,
	},

	{
		id: "11.1",
		type: "single",
		question:
			"¿Conoce las funciones y requisitos que debe cubrir para el puesto que actualmente ocupa?",
		options: ["Sí", "No"],
		required: true,
	},
	{
		id: "11.2",
		type: "single",
		question:
			"¿Su institución emite convocatoria pública y abierta para ocupar las plazas vacantes?",
		options: ["Sí", "No", "No sabe"],
		required: true,
	},
	{
		id: "11.3",
		type: "single",
		question:
			"¿Cuenta con un plan individual de carrera aprobado por su institución?",
		options: ["Sí", "No", "No sabe"],
		required: true,
	},
	{
		id: "11.4",
		type: "single",
		question:
			"¿La promoción o ascensos se realizan a través de concursos de selección públicos y abiertos?",
		options: ["Sí", "No", "No sabe"],
		required: true,
	},
	{
		id: "11.5",
		type: "single",
		question:
			"¿Sabía que como integrante del Servicio Profesional de Carrera tiene derecho a que se revisen las resoluciones disciplinarias emitidas en su contra mediante el Recurso de Rectificación?",
		options: ["Sí", "No"],
		required: true,
	},

	{
		id: "12",
		type: "single",
		question:
			"¿Se encuentra inscrito en el Registro Nacional de Personal de Seguridad Pública?",
		options: ["Sí", "No", "No sabe"],
		nextQuestion: {
			No: "13",
			"No sabe": "13",
		},
		required: true,
	},
	{
		id: "12.1",
		type: "single",
		question: "¿Cuenta con Clave Única de Identificación Personal (CUIP)?",
		options: ["Sí", "No", "No sabe"],
		required: true,
	},
	{
		id: "12.2",
		type: "single",
		question: "¿Cuenta con el Certificado Único Policial (CUP)?",
		options: ["Sí", "No", "No sabe"],
		required: true,
	},
	{
		id: "13",
		type: "group",
		question:
			"¿Con cuál de las siguientes prestaciones cuenta? Seleccione todas las opciones que apliquen, conforme a la siguiente codificación:",
		subQuestions: [
			{
				id: "13.1",
				type: "single",
				question: "13.1 Aguinaldo",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.2",
				type: "single",
				question: "13.2 Vacaciones",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.3",
				type: "single",
				question: "13.3 Seguro de vida",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.4",
				type: "single",
				question: "13.4 ISSSTE/IMSS",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.5",
				type: "single",
				question: "13.5 Días de permiso",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.6",
				type: "single",
				question: "13.6 Fondo de retiro",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.7",
				type: "single",
				question: "13.7 Licencia de maternidad/paternidad",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.8",
				type: "single",
				question: "13.8 Servicios médicos dentro de las instalaciones",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.9",
				type: "single",
				question: "13.9 Créditos para la vivienda",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.10",
				type: "single",
				question: "13.10 Apoyo para gastos funerarios",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.11",
				type: "single",
				question: "13.11 Comedor dentro de las instalaciones",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.12",
				type: "single",
				question: "13.12 Áreas deportivas",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.13",
				type: "single",
				question: "13.13 Vales de despensa",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.14",
				type: "single",
				question: "13.14 Becas para sus hijos",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.15",
				type: "single",
				question: "13.15 Servicios psicológicos",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.16",
				type: "single",
				question: "13.16 Apoyo educativo",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.17",
				type: "single",
				question:
					"13.17 Apoyo para la vivienda (Incluye: apoyo para la remodelación, construcción y adquisición de vivienda)",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.18",
				type: "single",
				question: "13.18 Apoyo económico para viudas",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.19",
				type: "single",
				question: "13.19 Seguro de gastos médicos mayores",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.20",
				type: "single",
				question: "13.20 Ahorro solidario",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.21",
				type: "single",
				question: "13.21 Guardería",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.22",
				type: "single",
				question: "13.22 Créditos automotrices",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.23",
				type: "single",
				question: "13.23 Ayuda para transporte",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.24",
				type: "single",
				question: "13.24 Seguridad social estatal",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "13.25",
				type: "single",
				question: "13.25 Otras prestaciones",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
		],
	},
	{
		id: "14",
		type: "single",
		question:
			"Desde su ingreso a la corporación ¿ha recibido cursos de capacitación?",
		options: ["Sí", "No"],
		nextQuestion: {
			No: "20",
		},
		required: true,
	},
	{
		id: "15",
		type: "group",
		question:
			"¿De qué tipo? Seleccione todas las opciones que apliquen, conforme a la siguiente codificación:",
		subQuestions: [
			{
				id: "15.1",
				type: "single",
				question: "15.1 Formación inicial",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "15.2",
				type: "single",
				question: "15.2 Formación continua",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
		],
	},

	{
		id: "16",
		type: "single",
		question: "¿Cuántos cursos de capacitación ha recibido en el último año?",
		options: ["Ninguno", "De 1 a 3", "De 4 a 6", "De 7 a 10", "Más de 10"],
		nextQuestion: {
			Ninguno: "20",
		},
		required: true,
	},
	{
		id: "17",
		type: "group",
		question:
			"¿Cuáles fueron los temas de los cursos a los que asistió en el último año y la calificación que le asignaría en términos de utilidad para el desempeño de sus funciones? Seleccione todas las opciones que apliquen, conforme a la siguiente codificación:",
		subQuestions: [
			{
				id: "17.1",
				type: "single",
				question: "17.1 Formación inicial para Policía de Proximidad",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.2",
				type: "single",
				question: "17.2 Formación inicial",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.3",
				type: "single",
				question: "17.3 Competencias Básicas de la Función",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.4",
				type: "single",
				question: "17.4 Protocolo de Actuación Policial ante Hechos Delictivos",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.5",
				type: "single",
				question:
					"17.5 Protocolo de Actuación Policial ante Casos de Violencia contra las Mujeres y Feminicidios",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.6",
				type: "single",
				question: "17.6 Protocolo para la Atención a Víctimas Vulnerables",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.7",
				type: "single",
				question: "17.7 Técnicas y Tácticas de Reacción a la Emboscada",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.8",
				type: "single",
				question: "17.8 Medicina Táctica",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.9",
				type: "single",
				question:
					"17.9 Curso de especialización para Policía Procesal en el Sistema de Justicia Penal",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.10",
				type: "single",
				question:
					"17.10 Curso de especialización para Integrantes de las Unidades de Policía Cibernética",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.11",
				type: "single",
				question:
					"17.11 Curso de actualización de la Ley Nacional de Ejecución Penal para personal penitenciario",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.12",
				type: "single",
				question:
					"17.12 Curso de especialización para Operadores del Sistema Integral de Justicia Penal para Adolescentes",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.13",
				type: "single",
				question:
					"17.13 Curso de actualización para intervinientes en el Sistema Integral de Justicia Penal para Adolescentes",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.14",
				type: "single",
				question:
					"17.14 Curso de especialización para Unidades Especializadas contra el Secuestro (UECS)",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.15",
				type: "single",
				question:
					"17.15 Curso de especialización para Facilitador en Mecanismos Alternativos de Solución de Controversias en materia penal",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.16",
				type: "single",
				question:
					"17.16 Curso de especialización para los Operadores del Sistema Integral de Justicia Penal para Adolescentes con enfoque en Facilitadores de Mecanismos Alternativos",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.17",
				type: "single",
				question:
					"17.17 Formación Inicial para Asesor Jurídico de Víctimas en el Sistema de Justicia Penal",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.18",
				type: "single",
				question:
					"17.18 Curso de especialización para Asesor Jurídico de Víctimas",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.19",
				type: "single",
				question:
					"17.19 Curso sobre Derechos Humanos de las Víctimas (Reparación Integral)",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.20",
				type: "single",
				question:
					"17.20 Curso sobre Derechos Humanos de las Víctimas (Perspectiva de género)",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.21",
				type: "single",
				question:
					"17.21 Curso sobre Derechos Humanos de las Víctimas (trata de personas)",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.22",
				type: "single",
				question:
					"17.22 Curso taller de especialización para Ministerio Público Orientador en el Sistema de Justicia Penal",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.23",
				type: "single",
				question:
					"17.23 Formación inicial para Guías Técnicos en el Sistema Integral de Justicia Penal para Adolecentes",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.24",
				type: "single",
				question: "17.24 Protocolo Minnesota",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.25",
				type: "single",
				question: "17.25 Protocolo de Derechos Humanos y Tortura",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.26",
				type: "single",
				question: "17.26 Protocolo de Estambul",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.27",
				type: "single",
				question:
					"17.27 Protocolo Nacional de Actuación. Primer respondiente e Informe Policial Homologado",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.28",
				type: "single",
				question: "17.28 Justicia Cívica",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.29",
				type: "single",
				question: "17.29 Proximidad",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.30",
				type: "single",
				question:
					"17.30 Protocolo de Investigación del Modelo Nacional de Policía y Justicia Cívica",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
			{
				id: "17.31",
				type: "single",
				question: "17.31 Cursos de Formación continua u Otros",
				options: [
					"No participación",
					"Mala",
					"Regular",
					"Buena",
					"Muy Buena",
					"Excelente",
				],
				required: true,
			},
		],
	},
	{
		id: "18",
		type: "single",
		question: "¿En el año 2024 recibió capacitación en el extranjero?",
		options: ["Si", "No"],
		nextQuestion: {
			No: "20",
		},
		required: true,
	},
	{
		id: "19",
		type: "single",
		question:
			"¿Con los conocimientos adquiridos en el extranjero, ha impartido cursos al personal operativo?",
		options: ["Si", "No"],
		required: true,
	},
	{
		id: "20",
		type: "single",
		question:
			"EVALUACIÓN POLICIAL ¿Cuándo fue la última vez que le aplicaron exámenes de control de confianza?",
		options: ["Nunca", "En el 2024", "En el 2023", "En el 2022", "En el 2021"],
		nextQuestion: {
			Nunca: "21",
		},
		required: true,
	},
	{
		id: "20.1",
		type: "group",
		question:
			"En caso de haber sido evaluado en control de confianza, ¿cómo califica los aspectos relacionados a la evaluación que le practicaron? Seleccione las opciones conforme a la siguiente codificación:",
		subQuestions: [
			{
				id: "20.1.1",
				type: "single",
				question: "20.1.1 Organización y logística del proceso de evaluación",
				options: ["Malo", "Regular", "Bueno", "Muy Bueno", "Excelente"],
				required: true,
			},
			{
				id: "20.1.2",
				type: "single",
				question: "20.1.2 Trato por parte del personal evaluador",
				options: ["Malo", "Regular", "Bueno", "Muy Bueno", "Excelente"],
				required: true,
			},
			{
				id: "20.1.3",
				type: "single",
				question: "20.1.3 Tiempo empleado en las evaluaciones",
				options: ["Malo", "Regular", "Bueno", "Muy Bueno", "Excelente"],
				required: true,
			},
			{
				id: "20.1.4",
				type: "single",
				question: "20.1.4 Equipo utilizado",
				options: ["Malo", "Regular", "Bueno", "Muy Bueno", "Excelente"],
				required: true,
			},
			{
				id: "20.1.5",
				type: "single",
				question:
					"20.1.5 Instalaciones (condiciones del inmueble y mobiliario)",
				options: ["Malo", "Regular", "Bueno", "Muy Bueno", "Excelente"],
				required: true,
			},
		],
	},
	{
		id: "20.2",
		type: "single",
		question:
			"¿Conoce el resultado de su última evaluación de control de confianza?",
		options: ["Sí", "No"],
		required: true,
	},
	{
		id: "20.3",
		type: "single",
		question:
			"¿Le han realizado el examen toxicológico (detección de consumo de drogas ilícitas) de manera sorpresiva, es decir, sin ser notificado con anticipación?",
		options: ["Sí", "No"],
		required: true,
	},
	{
		id: "20.4",
		type: "single",
		question:
			"¿Considera que la evaluación de control de confianza contribuye al fortalecimiento de las Instituciones de Seguridad Pública?",
		options: ["Sí", "No", "No sabe"],
		required: true,
	},
	{
		id: "21",
		type: "single",
		question:
			"¿Le han aplicado la evaluación de competencias básicas de la función?",
		options: ["Sí", "No"],
		nextQuestion: {
			No: "22",
		},
		required: true,
	},
	{
		id: "21.1",
		type: "single",
		question:
			"¿Conoció el resultado de la evaluación de competencias básicas de la función?",
		options: ["Sí", "No", "No recuerda"],
		required: true,
	},
	{
		id: "21.2",
		type: "single",
		question:
			"Previo a la evaluación de competencias básicas, ¿Recibió capacitación de las siete competencias de la función?",
		options: ["Sí", "No", "No recuerda"],
		required: true,
	},
	{
		id: "21.3",
		type: "single",
		question:
			"¿El lugar donde se llevó a cabo la evaluación de competencias básicas, contó con la infraestructura adecuada y los recursos necesarios?",
		options: ["Sí", "No", "No recuerda"],
		required: true,
	},
	{
		id: "21.4",
		type: "single",
		question:
			"¿Se presentó alguna dificultad al momento de realizar la evaluación de competencias básicas de la función?",
		options: ["Sí", "No"],
		nextQuestion: {
			No: "22",
		},
		required: true,
	},
	{
		id: "21.4.1",
		type: "single",
		question: "Si se presentó alguna dificultad, ¿a que se le atribuyó?",
		options: [
			"Logística y control de la institución evaluadora",
			"Falta de la infraestructura y equipo",
			"Desempeño del instructor evaluador",
			"Diseño y planteamiento de la evaluación",
		],
		required: true,
	},
	{
		id: "22",
		type: "single",
		question:
			"¿Le han aplicado evaluación del desempeño del personal en activo o del desempeño académico si es de nuevo ingreso?",
		options: ["Sí", "No", "No aplica"],
		nextQuestion: {
			No: "23",
			"No aplica": "23",
		},
		required: true,
	},
	{
		id: "22.1",
		type: "single",
		question:
			"¿Conoció el resultado de la evaluación del desempeño del personal en activo o del desempeño académico si es de nuevo ingreso?",
		options: ["Sí", "No", "No recuerda"],
		required: true,
	},
	{
		id: "22.2",
		type: "single",
		question:
			"¿En la aplicación de su evaluación del desempeño del personal en activo participó su superior jerárquico, la Comisión del Servicio Profesional de Carrera y de Honor y Justicia?",
		options: ["Sí", "No", "No sabe"],
		required: true,
	},
	{
		id: "22.3",
		type: "single",
		question:
			"¿Tiene conocimiento de que la evaluación del desempeño del personal en activo la realiza su superior jerárquico?",
		options: ["Sí", "No", "No sabe"],
		required: true,
	},
	{
		id: "22.4",
		type: "single",
		question:
			"¿Sabía que la evaluación del desempeño del personal en activo mide el cumplimiento de sus funciones (productividad) y metas, en apego a los principios de legalidad, objetividad, eficiencia, profesionalismo, honradez y respeto a los derechos humanos, así como a la disciplina que rige su actuación?",
		options: ["Sí", "No", "No sabe"],
		required: true,
	},
	{
		id: "23",
		type: "group",
		question:
			"EQUIPAMIENTO. Indique en la siguiente lista, la calidad del equipamiento que en su caso le fue entregado en el año 2024. Seleccione todas las opciones que apliquen, utilizando la siguiente codificación, considerando su apreciación sobre los equipos recibidos:",
		categories: [
			{
				category: "Uniformes",
				subQuestions: [
					{
						id: "23.1",
						type: "single",
						question: "23.1 Camisa o camisola",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.2",
						type: "single",
						question: "23.2 Pantalón",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.3",
						type: "single",
						question: "23.3 Calzado (par de botas o zapato tipo choclos)",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.4",
						type: "single",
						question: "23.4 Gorra tipo beisbolera",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.5",
						type: "single",
						question: "23.5 Playera",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.6",
						type: "single",
						question: "23.6 Chamarra",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
				],
			},
			{
				category: "Protección Personal",
				subQuestions: [
					{
						id: "23.7",
						type: "single",
						question: "23.7 Chaleco balístico",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.8",
						type: "single",
						question: "23.8 Casco balístico",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
				],
			},
			{
				category: "Armamento",
				subQuestions: [
					{
						id: "23.9",
						type: "single",
						question: "23.9 Arma corta",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.10",
						type: "single",
						question: "23.10 Arma larga",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.11",
						type: "single",
						question: "23.11 Municiones",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.12",
						type: "single",
						question: "23.12 Cargadores",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
				],
			},
			{
				category: "Accesorios",
				subQuestions: [
					{
						id: "23.13",
						type: "single",
						question: "23.13 Fornitura",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.14",
						type: "single",
						question: "23.14 Tolete",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.15",
						type: "single",
						question: "23.15 Casco Táctico",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.16",
						type: "single",
						question: "23.16 Escudo de acrílico",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.17",
						type: "single",
						question: "23.17 Candados de manos o Esposas",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.18",
						type: "single",
						question: "23.18 Lámpara de mano",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.19",
						type: "single",
						question: "23.19 Gas lacrimógeno",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
				],
			},
			{
				category: "Equipamiento",
				subQuestions: [
					{
						id: "23.20",
						type: "single",
						question: "23.20 Motocicleta",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.21",
						type: "single",
						question: "23.21 Patrulla (camioneta)",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.22",
						type: "single",
						question: "23.22 Patrulla (sedán)",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.23",
						type: "single",
						question: "23.23 Equipo de radiocomunicación",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.24",
						type: "single",
						question: "23.24 Instrumental de investigación y criminalística",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.25",
						type: "single",
						question: "23.25 Equipo de cómputo",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.26",
						type: "single",
						question: "23.26 Kit de primer respondiente",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.27",
						type: "single",
						question: "23.27 Tableta",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
					{
						id: "23.28",
						type: "single",
						question: "23.28 Cámara de solapa",
						options: [
							"No se recibió equipo en el año",
							"No sabe",
							"Mala",
							"Regular",
							"Buena",
							"Muy Buena",
						],
						required: true,
					},
				],
			},
		],
	},
	{
		id: "24",
		type: "single",
		question:
			"¿Cuenta con el equipamiento necesario para desarrollar las funciones de primer respondiente?",
		options: ["Sí", "No", "No sabe"],
		required: true,
	},
	{
		id: "25",
		type: "group",
		question:
			"¿Con qué frecuencia le otorgan específicamente el siguiente uniforme: Seleccione todas las opciones que apliquen, conforme a la siguiente codificación:",
		subQuestions: [
			{
				id: "25.1",
				type: "single",
				question: "25.1 Camisa o Camisola",
				options: [
					"1 vez por año",
					"2 vez por año",
					"Sin una frecuencia preestablecida",
					"Nunca",
				],
				required: true,
			},
			{
				id: "25.2",
				type: "single",
				question: "25.2 Pantalón",
				options: [
					"1 vez por año",
					"2 vez por año",
					"Sin una frecuencia preestablecida",
					"Nunca",
				],
				required: true,
			},
			{
				id: "25.3",
				type: "single",
				question: "25.3 Calzado (par de botas o Zapato tipo choclos)",
				options: [
					"1 vez por año",
					"2 vez por año",
					"Sin una frecuencia preestablecida",
					"Nunca",
				],
				required: true,
			},
			{
				id: "25.4",
				type: "single",
				question: "25.4 Gorra tipo de beisbolera",
				options: [
					"1 vez por año",
					"2 vez por año",
					"Sin una frecuencia preestablecida",
					"Nunca",
				],
				required: true,
			},
			{
				id: "25.5",
				type: "single",
				question: "25.5 Playera",
				options: [
					"1 vez por año",
					"2 vez por año",
					"Sin una frecuencia preestablecida",
					"Nunca",
				],
				required: true,
			},
			{
				id: "25.6",
				type: "single",
				question: "25.6 Chamarra",
				options: [
					"1 vez por año",
					"2 vez por año",
					"Sin una frecuencia preestablecida",
					"Nunca",
				],
				required: true,
			},
			{
				id: "25.7",
				type: "single",
				question: "25.7 Uniforme de Gala",
				options: [
					"1 vez por año",
					"2 vez por año",
					"Sin una frecuencia preestablecida",
					"Nunca",
				],
				required: true,
			},
			{
				id: "25.8",
				type: "single",
				question: "25.8 Fornitura",
				options: [
					"1 vez por año",
					"2 vez por año",
					"Sin una frecuencia preestablecida",
					"Nunca",
				],
				required: true,
			},
		],
	},
	{
		id: "26",
		type: "group",
		question:
			"USO DE TECNOLOGÍA ¿Tiene conocimiento sobre el uso y operación de las siguientes tecnologías de información y comunicaciones actuales? Seleccione la opción que aplique conforme a la siguiente codificación:",
		subQuestions: [
			{
				id: "26.1",
				type: "single",
				question: "26.1 Equipo de radiocomunicación",
				options: ["Sí", "No"],
				required: true,
			},
			{
				id: "26.2",
				type: "single",
				question: "26.2 Computadora",
				options: ["Sí", "No"],
				required: true,
			},
			{
				id: "26.3",
				type: "single",
				question: "26.3 Cámara fotográfica",
				options: ["Sí", "No"],
				required: true,
			},
			{
				id: "26.4",
				type: "single",
				question: "26.4 Cámara de video",
				options: ["Sí", "No"],
				required: true,
			},
			{
				id: "26.5",
				type: "single",
				question: "26.5 Tabletas",
				options: ["Sí", "No"],
				required: true,
			},
			{
				id: "26.6",
				type: "single",
				question: "26.6 Teléfono Inteligente",
				options: ["Sí", "No"],
				required: true,
			},
			{
				id: "26.7",
				type: "single",
				question: "26.7 Manejo de Internet",
				options: ["Sí", "No"],
				required: true,
			},
			{
				id: "26.8",
				type: "single",
				question: "26.8 Redes sociales",
				options: ["Sí", "No"],
				required: true,
			},
			{
				id: "26.9",
				type: "single",
				question: "26.9 Redes locales (intranet)",
				options: ["Sí", "No"],
				required: true,
			},
			{
				id: "26.10",
				type: "single",
				question: "26.10 Correo electrónico",
				options: ["Sí", "No"],
				required: true,
			},
			{
				id: "26.11",
				type: "single",
				question: "26.11 Dron",
				options: ["Sí", "No"],
				required: true,
			},
		],
	},
	{
		id: "27",
		type: "single",
		question:
			"¿Cuál de las siguientes fases del proceso del Informe Policial Homologado (IPH) 2024 considera la más difícil?",
		options: [
			"Llenado del IPH",
			"Entrega/Recepción al Ministerio Público",
			"Captura en el aplicativo de Plataforma México",
		],
		required: true,
	},
	{
		id: "28",
		type: "single",
		question: "¿Cuál es la percepción que tiene sobre el IPH 2024?",
		options: ["Buena", "Regular", "Mala", "Ninguna"],
		required: true,
	},
	{
		id: "29",
		type: "single",
		question: "¿Se siente capacitado para llenar el IPH 2024?",
		options: ["Mucho", "Regular", "Poco", "Nada"],
		required: true,
	},
	{
		id: "30",
		type: "single",
		question:
			"¿Qué herramienta utiliza cotidianamente para escribir sus reportes, informes u otros escritos oficiales de trabajo?",
		options: [
			"Computadora",
			"Tabletas/teléfonos inteligentes",
			"Máquina de escribir",
			"Los hace a mano",
			"No elabora reportes, informes o escritos",
		],
		required: true,
	},
	{
		id: "31",
		type: "group",
		question:
			"¿Qué elemento considera necesario para un adecuado llenado del IPH 2024? Seleccione la opción que aplique conforme a la siguiente codificación:",
		subQuestions: [
			{
				id: "31.1",
				type: "single",
				question: "31.1 Capacitación",
				options: ["Sí", "No"],
				required: true,
			},
			{
				id: "31.2",
				type: "single",
				question: "31.2 Infraestructura tecnológica",
				options: ["Sí", "No"],
				required: true,
			},
			{
				id: "31.3",
				type: "single",
				question: "31.3 Disposición del superior",
				options: ["Sí", "No"],
				required: true,
			},
			{
				id: "31.4",
				type: "single",
				question: "31.4 Tiempo suficiente para el llenado",
				options: ["Sí", "No"],
				required: true,
			},
			{
				id: "31.5",
				type: "single",
				question: "31.5 Guía de llenado",
				options: ["Sí", "No"],
				required: true,
			},
		],
	},
	{
		id: "32",
		type: "single",
		question:
			"¿En el desempeño de su trabajo, ha solicitado información del Sistema Nacional de Información (SNI) respecto de vehículos robados, mandamientos judiciales u otra información?",
		options: ["Sí", "No", "No sabe"],
		required: true,
	},
	{
		id: "33",
		type: "single",
		question:
			"¿En su área de trabajo cuenta con equipo para consultar el Sistema Nacional de Información (SNI)?",
		options: ["Sí", "No", "No sabe"],
		required: true,
	},
	{
		id: "34",
		type: "group",
		question:
			"¿Qué métodos de identificación y registro de personal tiene su institución? Seleccione todas las opciones que apliquen, conforme a la siguiente codificación:",
		subQuestions: [
			{
				id: "34.1",
				type: "single",
				question: "34.1 Clave Única de Identificación Personal (CUIP)",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "34.2",
				type: "single",
				question: "34.2 Huellas dactilares",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "34.3",
				type: "single",
				question: "34.3 Registro de voz",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
			{
				id: "34.4",
				type: "single",
				question: "34.4 Identificación con fotografía (actualizada)",
				options: ["Sí", "No", "No sabe"],
				required: true,
			},
		],
	},
	{
		id: "35",
		type: "single",
		question:
			"INFRAESTRUCTURA En general, ¿cómo considera el estado físico actual de las instalaciones donde opera?",
		options: ["Excelente", "Muy Bueno", "Bueno", "Regular", "Malo"],
		required: true,
	},
	{
		id: "36",
		type: "single",
		question:
			"¿Considera que hubo mejoras físicas a las instalaciones de la institución donde labora durante el 2024?",
		options: ["Sí", "No"],
		nextQuestion: {
			No: "37",
		},
		required: true,
	},
	{
		id: "36.1",
		type: "single",
		question:
			"Indique si las mejoras, ampliaciones o construcciones ejecutadas, cumplen con la calidad que usted esperaba",
		options: ["Sí", "No", "Poco"],
		required: true,
	},
	{
		id: "36.2",
		type: "single",
		question:
			"Mencione si las mejoras, ampliaciones o construcciones realizadas a las instalaciones donde actualmente labora, resuelven las necesidades de infraestructura de la institución",
		options: ["Sí", "No", "Parcialmente"],
		required: true,
	},
	{
		id: "36.3",
		type: "single",
		question:
			"Mencione si las mejoras, ampliaciones o construcciones realizadas a las instalaciones donde actualmente labora, influyen en la mejora de su capacidad operativa y/o de respuesta",
		options: ["Sí", "No", "Poco"],
		required: true,
	},
	{
		id: "37",
		type: "single",
		question:
			"¿Considera que las instalaciones en las que labora actualmente le permiten operar de manera eficiente y eficaz?",
		options: ["Sí", "No"],
		nextQuestion: {
			Sí: "38",
		},
		required: true,
	},
	{
		id: "37.1",
		type: "group",
		question:
			"Si la respuesta es NO, indique la razón, marcando las opciones siguientes. Seleccione todas las opciones que apliquen, conforme a la siguiente codificación:",
		subQuestions: [
			{
				id: "37.1",
				type: "single",
				question: "37.1 Faltan espacios",
				options: ["Sí aplica", "No aplica"],
				required: true,
			},
			{
				id: "37.2",
				type: "single",
				question: "37.2 Los espacios están en malas condiciones",
				options: ["Sí aplica", "No aplica"],
				required: true,
			},
			{
				id: "37.3",
				type: "single",
				question: "37.3 No hay suficiente capacidad",
				options: ["Sí aplica", "No aplica"],
				required: true,
			},
			{
				id: "37.4",
				type: "single",
				question: "37.4 Los espacios no son los adecuados para la operación",
				options: ["Sí aplica", "No aplica"],
				required: true,
			},
		],
	},
	{
		id: "38",
		type: "single",
		question:
			"¿Considera necesario que se lleven a cabo mejoras, ampliaciones o construcciones a las instalaciones donde actualmente labora?",
		options: ["Sí", "No"],
		required: true,
	},
	{
		id: "39",
		type: "single",
		question:
			"¿Qué tan orgulloso se siente de ser Policía Estatal/Policía Ministerial/Custodio?",
		options: ["Muy orgulloso", "Orgulloso", "Poco orgulloso", "Nada orgulloso"],
		required: true,
	},
	{
		id: "40",
		type: "single",
		question:
			"¿Cuál es la razón por la que es Policía Estatal y/o Policía Ministerial y/o Custodio?",
		options: [
			"Por necesidad económica o desempleo",
			"Porque siempre le llamó la atención",
			"Porque era la mejor opción disponible",
			"Para tener estabilidad económica",
			"Para ayudar a la gente",
			"Porque quiere combatir la inseguridad",
			"Por convencimiento de amistades",
			"Por herencia familiar",
			"Desarrollo profesional o personal",
			"Por vocación",
		],
		required: true,
	},
	{
		id: "41",
		type: "single",
		question:
			"¿En qué situación se encuentra en su comunidad derivado de ser Policía Estatal y/o Policía Ministerial y/o Custodio?",
		options: [
			"Reconocimiento en su comunidad",
			"Respeto en su comunidad",
			"Discriminación en su comunidad",
			"Ninguna de las anteriores",
		],
		required: true,
	},
	{
		id: "42",
		type: "group",
		question:
			"De la siguiente lista, indique qué le gusta de su trabajo. Seleccione todas las opciones que apliquen, conforme a la siguiente codificación:",
		subQuestions: [
			{
				id: "42.1",
				type: "single",
				question: "42.1 Proteger y servir a la sociedad",
				options: ["Le gusta", "No le gusta", "Indistinto"],
				required: true,
			},
			{
				id: "42.2",
				type: "single",
				question: "42.2 Investigar los hechos delictivos",
				options: ["Le gusta", "No le gusta", "Indistinto"],
				required: true,
			},
			{
				id: "42.3",
				type: "single",
				question: "42.3 Investigar y analizar las evidencias",
				options: ["Le gusta", "No le gusta", "Indistinto"],
				required: true,
			},
			{
				id: "42.4",
				type: "single",
				question: "42.4 Brindar seguridad/vigilar",
				options: ["Le gusta", "No le gusta", "Indistinto"],
				required: true,
			},
			{
				id: "42.5",
				type: "single",
				question: "42.5 Ayudar a las personas",
				options: ["Le gusta", "No le gusta", "Indistinto"],
				required: true,
			},
			{
				id: "42.6",
				type: "single",
				question: "42.6 Contacto con la población",
				options: ["Le gusta", "No le gusta", "Indistinto"],
				required: true,
			},
			{
				id: "42.7",
				type: "single",
				question: "42.7 Portar el uniforme",
				options: ["Le gusta", "No le gusta", "Indistinto"],
				required: true,
			},
			{
				id: "42.8",
				type: "single",
				question: "42.8 Combatir a la delincuencia",
				options: ["Le gusta", "No le gusta", "Indistinto"],
				required: true,
			},
			{
				id: "42.9",
				type: "single",
				question: "42.9 El sueldo y las prestaciones",
				options: ["Le gusta", "No le gusta", "Indistinto"],
				required: true,
			},
			{
				id: "42.10",
				type: "single",
				question:
					"42.10 Presentar ante la autoridad competente a los responsables de delitos",
				options: ["Le gusta", "No le gusta", "Indistinto"],
				required: true,
			},
		],
	},
	{
		id: "43",
		type: "single",
		question: "¿Ha intervenido como primer respondiente?",
		options: ["Sí", "No", "No sabe"],
		required: true,
	},
	{
		id: "44",
		type: "single",
		question: "¿Han participado en audiencias del Sistema Penal Acusatorio?",
		options: ["Sí", "No"],
		required: true,
	},
	{
		id: "45",
		type: "single",
		question: "¿Cuál de las siguientes frases se acerca más a la que piensa?",
		options: [
			"Las leyes deben aplicarse a todos por igual",
			"Pueden hacerse excepciones en algunos casos",
		],
		required: true,
	},
	{
		id: "46",
		type: "group",
		question:
			"¿Cuántas veces a la semana realiza las siguientes actividades? Seleccione todas las opciones que apliquen, conforme a la siguiente codificación:",
		subQuestions: [
			{
				id: "46.1",
				type: "single",
				question: "46.1 Entrenamiento físico",
				options: [
					"De uno a dos veces",
					"De tres a cuatro veces",
					"De cinco a seis veces",
					"Ninguna",
				],
				required: true,
			},
			{
				id: "46.2",
				type: "single",
				question: "46.2 Reuniones del mando con sus subordinados",
				options: [
					"De uno a dos veces",
					"De tres a cuatro veces",
					"De cinco a seis veces",
					"Ninguna",
				],
				required: true,
			},
			{
				id: "46.3",
				type: "single",
				question: "46.3 Inspección física de uniforme y calzado",
				options: [
					"De uno a dos veces",
					"De tres a cuatro veces",
					"De cinco a seis veces",
					"Ninguna",
				],
				required: true,
			},
			{
				id: "46.4",
				type: "single",
				question: "46.4 Revisión del armamento (arme y desarme)",
				options: [
					"De uno a dos veces",
					"De tres a cuatro veces",
					"De cinco a seis veces",
					"Ninguna",
				],
				required: true,
			},
			{
				id: "46.5",
				type: "single",
				question: "46.5 Calibración de instrumental",
				options: [
					"De uno a dos veces",
					"De tres a cuatro veces",
					"De cinco a seis veces",
					"Ninguna",
				],
				required: true,
			},
			{
				id: "46.6",
				type: "single",
				question: "46.6 Actualización de normatividad",
				options: [
					"De uno a dos veces",
					"De tres a cuatro veces",
					"De cinco a seis veces",
					"Ninguna",
				],
				required: true,
			},
			{
				id: "46.7",
				type: "single",
				question: "46.7 Pases de lista",
				options: [
					"De uno a dos veces",
					"De tres a cuatro veces",
					"De cinco a seis veces",
					"Ninguna",
				],
				required: true,
			},
			{
				id: "46.8",
				type: "single",
				question: "46.8 Cumplimiento de Mandamientos",
				options: [
					"De uno a dos veces",
					"De tres a cuatro veces",
					"De cinco a seis veces",
					"Ninguna",
				],
				required: true,
			},
		],
	},
	{
		id: "47",
		type: "single",
		question:
			"¿Cuál de los siguientes criterios, considera que es el de mayor importancia en su institución para otorgar ascensos?",
		options: [
			"Antigüedad en el puesto",
			"Capacitación",
			"Desempeño laboral",
			"Favoritismo",
			"Puntualidad",
		],
		required: true,
	},
	{
		id: "48",
		type: "group",
		question:
			"De la siguiente lista, ¿qué aspectos considera que son escenarios para mejorar el trabajo de su corporación? Seleccione la opción que aplique, conforme a la siguiente codificación:",
		subQuestions: [
			{
				id: "48.1",
				type: "single",
				question: "48.1 Personal",
				options: ["Hace falta", "No hace falta"],
				required: true,
			},
			{
				id: "48.2",
				type: "single",
				question: "48.2 Capacitación",
				options: ["Hace falta", "No hace falta"],
				required: true,
			},
			{
				id: "48.3",
				type: "single",
				question:
					"48.3 Equipo de trabajo (armamento, vehículos, instrumentación, chaleco balístico, casco balístico, equipo de comunicaciones, etc.)",
				options: ["Hace falta", "No hace falta"],
				required: true,
			},
			{
				id: "48.4",
				type: "single",
				question: "48.4 Lealtad y ética laboral",
				options: ["Hace falta", "No hace falta"],
				required: true,
			},
			{
				id: "48.5",
				type: "single",
				question: "48.5 Mejor trato y motivación por parte de los superiores",
				options: ["Hace falta", "No hace falta"],
				required: true,
			},
			{
				id: "48.6",
				type: "single",
				question: "48.6 Trabajo en equipo y compañerismo",
				options: ["Hace falta", "No hace falta"],
				required: true,
			},
			{
				id: "48.7",
				type: "single",
				question: "48.7 Oprtunidades de crecimiento",
				options: ["Hace falta", "No hace falta"],
				required: true,
			},
			{
				id: "48.8",
				type: "single",
				question: "48.8 Mejor sueldo y prestaciones",
				options: ["Hace falta", "No hace falta"],
				required: true,
			},
			{
				id: "48.9",
				type: "single",
				question: "48.9 Instalaciones adecuadas",
				options: ["Hace falta", "No hace falta"],
				required: true,
			},
		],
	},
	{
		id: "49",
		type: "group",
		question: "¿Qué problemas considera que existen dentro de su Institución?",
		subQuestions: [
			{
				id: "49.1",
				type: "single",
				question: "49.1 Maltrato y/o intimidación por parte de sus superiores",
				options: ["Existe", "No existe", "No sabe"],
				required: true,
			},
			{
				id: "49.2",
				type: "single",
				question: "49.2 Corrupción de elementos policiales",
				options: ["Existe", "No existe", "No sabe"],
				required: true,
			},
			{
				id: "49.3",
				type: "single",
				question:
					"49.3 Falta de actitud de servicio por parte de los elementos",
				options: ["Existe", "No existe", "No sabe"],
				required: true,
			},
			{
				id: "49.4",
				type: "single",
				question: "49.4 Falta de confianza entre los elementos",
				options: ["Existe", "No existe", "No sabe"],
				required: true,
			},
			{
				id: "49.5",
				type: "single",
				question: "49.5 Falta de liderazgo de sus mandos",
				options: ["Existe", "No existe", "No sabe"],
				required: true,
			},
			{
				id: "49.6",
				type: "single",
				question: "49.6 Falta de organización",
				options: ["Existe", "No existe", "No sabe"],
				required: true,
			},
			{
				id: "49.7",
				type: "single",
				question:
					"49.7 Infiltración del crimen organizado dentro de su corporación",
				options: ["Existe", "No existe", "No sabe"],
				required: true,
			},
			{
				id: "49.8",
				type: "single",
				question: "49.8 Negligencia en la actuación de los elementos",
				options: ["Existe", "No existe", "No sabe"],
				required: true,
			},
			{
				id: "49.9",
				type: "single",
				question: "49.9 Conflictos personales entre elementos",
				options: ["Existe", "No existe", "No sabe"],
				required: true,
			},
			{
				id: "49.10",
				type: "single",
				question: "49.10 Acoso u hostigamiento sexual",
				options: ["Existe", "No existe", "No sabe"],
				required: true,
			},
		],
	},
];

export default function Survey() {
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const navigate = useNavigate();
	const [answers, setAnswers] = useState({});
	const [persona, setPersona] = useState({ CUIP: "" });
	const [iniciar, setIniciar] = useState(false);
	const [loading, setLoading] = useState(false);

	const cuipPattern = /^[a-zA-Z]{4}\d{6}[a-zA-Z]\d{2}\d+$/;

	const currentQuestion = Preguntas[currentQuestionIndex];

	const handleAnswerChange = (event, questionId) => {
		const selectedAnswer = event.target.value;

		setAnswers({
			...answers,
			[questionId]: selectedAnswer,
		});

		// Guardar la respuesta seleccionada

		// Comprobar si la pregunta tiene un salto condicional
		// const currentQuestion = Preguntas.find((q) => q.id === questionId);

		// const answerText = currentQuestion.options[selectedAnswer - 1];

		// if (
		// 	currentQuestion?.nextQuestion &&
		// 	currentQuestion.nextQuestion[answerText]
		// ) {
		// 	// Saltar a la pregunta indicada en el JSON
		// 	const nextQuestionId = currentQuestion.nextQuestion[answerText];
		// 	const nextQuestionIndex = Preguntas.findIndex(
		// 		(q) => q.id === nextQuestionId
		// 	);

		// 	if (nextQuestionIndex !== -1) {
		// 		setCurrentQuestionIndex(nextQuestionIndex);
		// 	}
		// }
	};

	const handleMultipleChange = (event, option, questionId) => {
		const currentAnswers = answers[questionId] || [];
		if (event.target.checked) {
			setAnswers({
				...answers,
				[questionId]: [...currentAnswers, option],
			});
		} else {
			setAnswers({
				...answers,
				[questionId]: currentAnswers.filter((ans) => ans !== option),
			});
		}
	};

	const handleNextQuestion = () => {
		if (currentQuestionIndex < Preguntas.length - 1) {
			if (
				currentQuestionIndex === 20 ||
				currentQuestionIndex === 22 ||
				currentQuestionIndex === 28 ||
				currentQuestionIndex === 24 ||
				currentQuestionIndex === 43 ||
				currentQuestionIndex === 45 ||
				currentQuestionIndex === 46 ||
				currentQuestionIndex === 51 ||
				currentQuestionIndex === 54 ||
				currentQuestionIndex === 66 ||
				currentQuestionIndex === 70 ||
				currentQuestionIndex === 72
			) {
				setCurrentQuestionIndex(currentQuestionIndex + 1);
			} else {
				// console.log("=>currentQuestion.options: ", currentQuestion.options);
				// console.log("=>answers: ", answers);
				// console.log(
				// 	"=>String(currentQuestionIndex): ",
				// 	String(currentQuestion.id)
				// );
				// console.log(
				// 	"=>Resp: ",
				// 	currentQuestion.options[answers[String(currentQuestion.id)] - 1]
				// );

				if (
					currentQuestion?.nextQuestion &&
					currentQuestion.nextQuestion[
						currentQuestion.options[answers[String(currentQuestion.id)] - 1]
					]
				) {
					// Saltar a la pregunta indicada en el JSON
					const nextQuestionId =
						currentQuestion.nextQuestion[
							currentQuestion.options[answers[String(currentQuestion.id)] - 1]
						];
					const nextQuestionIndex = Preguntas.findIndex(
						(q) => q.id === nextQuestionId
					);

					if (nextQuestionIndex !== -1) {
						setCurrentQuestionIndex(nextQuestionIndex);
					}
				} else {
					setCurrentQuestionIndex(currentQuestionIndex + 1);
				}
			}
		} else {
			//console.log("=>Enviar setSuvey: ", JSON.stringify(answers, null, 2));

			setLoading(true);
			SurveyServices.setSurvey({
				CUIP: persona.CUIP,
				respuestas: answers,
			})
				.then((res) => {
					if (res.success && res.results) {
						Swal.fire({
							title: "Guardado correctamente.",
							html: "Los datos se guardaron correctamente.<br><br><strong>¿Desea agregar una nueva?</strong>",
							icon: "success",
							showCancelButton: true,
							confirmButtonColor: "#3085d6",
							cancelButtonColor: "#d33",
							confirmButtonText: "Sí, continuar",
							cancelButtonText: "Cancelar",
							allowOutsideClick: false,
							allowEscapeKey: false,
						}).then((result) => {
							if (result.isConfirmed) {
								setIniciar(false);
								setPersona({ CUIP: "" });
								setAnswers({});
								setCurrentQuestionIndex(0);
							} else {
								navigate("/survey");
							}
						});
					} else {
						Swal.fire({ title: res.message, icon: "warning" });
					}
				})
				.catch((e) => {
					Swal.fire({ title: e.message, icon: "warning" });
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const handlePreviousQuestion = () => {
		if (currentQuestionIndex > 0) {
			setCurrentQuestionIndex(currentQuestionIndex - 1);
		}
	};

	// Función para renderizar subpreguntas (normal o con categorías)
	const renderSubQuestions = () => {
		if (currentQuestion.categories) {
			// Renderizar por categorías
			return currentQuestion.categories.map((category, catIndex) => (
				<Box key={catIndex} sx={{ mt: 2 }}>
					<Typography variant="h6" gutterBottom>
						<h3>{category.category}</h3>
					</Typography>
					{category.subQuestions.map((subQuestion) => (
						<div key={subQuestion.id}>
							<Typography variant="subtitle1" gutterBottom>
								<h2>
									<strong>{subQuestion.question}</strong>
								</h2>
							</Typography>
							<RadioGroup
								value={answers[subQuestion.id] || ""}
								onChange={(event) => handleAnswerChange(event, subQuestion.id)}
							>
								{subQuestion.options.map((option, index) => (
									<FormControlLabel
										key={option}
										value={index + 1}
										control={<Radio />}
										label={option}
									/>
								))}
							</RadioGroup>
						</div>
					))}
				</Box>
			));
		} else if (currentQuestion.subQuestions) {
			// Renderizar subpreguntas sin categorías
			return currentQuestion.subQuestions.map((subQuestion) => (
				<div key={subQuestion.id}>
					<Typography variant="subtitle1" gutterBottom>
						<h3>{subQuestion.question}</h3>
					</Typography>
					<RadioGroup
						value={answers[subQuestion.id] || ""}
						onChange={(event) => handleAnswerChange(event, subQuestion.id)}
					>
						{subQuestion.options.map((option, index) => (
							<FormControlLabel
								key={option}
								value={index + 1}
								control={<Radio />}
								label={option}
							/>
						))}
					</RadioGroup>
				</div>
			));
		}
		return null;
	};

	const handleEmpezar = () => {
		setLoading(true);
		if (persona.CUIP.length < 18) {
			Swal.fire({ title: "CUIP necesaria", icon: "warning" });
			setLoading(false);
			return true;
		}

		if (!cuipPattern.test(persona.CUIP)) {
			Swal.fire({ title: "CUIP inválida", icon: "warning" });
			setLoading(false);
			return true;
		}
		// console.log(
		// 	"=>data: ",
		// 	JSON.stringify({
		// 		page: 0,
		// 		pageSize: 25,
		// 		filtered: [{ id: "c.CUIP", value: persona.CUIP, filter: "=" }],
		// 	})
		// );
		SurveyServices.getSurvey({
			page: 0,
			pageSize: 25,
			filtered: [{ id: "c.CUIP", value: persona.CUIP, filter: "=" }],
		})
			.then((res) => {
				console.log("=>res: ", res);
				if (res.success && res.results) {
					if (res.response.data.length > 0) {
						Swal.fire({
							title: "La encuesta de esta CUIP ya existe!",
							icon: "warning",
						});
						return true;
					}
					setIniciar(true);
				} else {
					Swal.fire({ title: res.message, icon: "warning" });
				}
			})
			.catch((e) => {
				Swal.fire({ title: e.message, icon: "warning" });
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Container maxWidth="sm">
			{iniciar ? (
				<Paper elevation={3} sx={{ p: 4, mt: 4 }}>
					<Typography variant="h6" gutterBottom>
						{`${currentQuestion.id}. ${currentQuestion.question}`}
					</Typography>

					{currentQuestion.type === "single" && (
						<RadioGroup
							value={answers[currentQuestion.id] || ""}
							onChange={(event) =>
								handleAnswerChange(event, currentQuestion.id)
							}
						>
							{currentQuestion.options.map((option, index) => (
								<FormControlLabel
									key={option}
									value={index + 1}
									control={<Radio />}
									label={option}
								/>
							))}
						</RadioGroup>
					)}

					{currentQuestion.type === "multiple" && (
						<>
							{currentQuestion.options.map((option) => (
								<FormControlLabel
									key={option}
									control={
										<Checkbox
											checked={
												answers[currentQuestion.id]
													? answers[currentQuestion.id].includes(option)
													: false
											}
											onChange={(e) =>
												handleMultipleChange(e, option, currentQuestion.id)
											}
										/>
									}
									label={option}
								/>
							))}
						</>
					)}

					{currentQuestion.type === "group" && renderSubQuestions()}

					<Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
						{!loading ? (
							<Button
								variant="contained"
								onClick={handlePreviousQuestion}
								disabled={currentQuestionIndex === 0}
							>
								Atrás
							</Button>
						) : (
							<CircularProgress
								variant={"indeterminate"}
								sx={{
									color: (theme) =>
										theme.palette.grey[
											theme.palette.mode === "light" ? 200 : 800
										],
								}}
								size={40}
								thickness={4}
								value={100}
							/>
						)}
						{!loading ? (
							<Button
								variant="contained"
								onClick={handleNextQuestion}
								disabled={
									currentQuestion.type === "group"
										? currentQuestion.subQuestions
											? currentQuestion.subQuestions.some(
													(sub) => !answers[sub.id] && sub.required
											  )
											: currentQuestion.categories.some((category) =>
													category.subQuestions.some(
														(sub) => !answers[sub.id] && sub.required
													)
											  )
										: !answers[currentQuestion.id] && currentQuestion.required
								}
							>
								{currentQuestionIndex < Preguntas.length - 1
									? "Siguiente"
									: "Finalizar"}
							</Button>
						) : (
							<CircularProgress
								variant={"indeterminate"}
								sx={{
									color: (theme) =>
										theme.palette.grey[
											theme.palette.mode === "light" ? 200 : 800
										],
								}}
								size={40}
								thickness={4}
								value={100}
							/>
						)}
					</Box>
				</Paper>
			) : (
				<Paper elevation={3} sx={{ p: 4, mt: 4 }}>
					<Typography variant="h6" gutterBottom>
						Iniciar encuestas
					</Typography>

					<Grid2 xs={12} sm={12} md={3} lg={3}>
						<TextField
							label="CUIP"
							type="text"
							name="CUIP"
							onChange={(value) =>
								setPersona({ CUIP: value.currentTarget.value })
							}
							value={persona.CUIP.toUpperCase()}
							size="small"
							variant="outlined"
							className="fixed-input"
						/>
					</Grid2>
					<br />
					{!loading ? (
						<Button
							variant="contained"
							onClick={handleEmpezar}
							loading={loading}
						>
							Validar CUIP
						</Button>
					) : (
						<CircularProgress
							variant={"indeterminate"}
							sx={{
								color: (theme) =>
									theme.palette.grey[
										theme.palette.mode === "light" ? 200 : 800
									],
							}}
							size={40}
							thickness={4}
							value={100}
						/>
					)}
				</Paper>
			)}

			{/* <Dialog open={showResults} onClose={handleCloseResults}>
				<DialogTitle>Resultados de la Encuesta</DialogTitle>
				<DialogContent>
					<Typography
						variant="body1"
						component="pre"
						sx={{ whiteSpace: "pre-wrap" }}
					>
						{JSON.stringify(answers, null, 2)}
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseResults}>Cerrar</Button>
				</DialogActions>
			</Dialog> */}
		</Container>
	);
}
