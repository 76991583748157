import { ApiExec } from "@utils/ApiExec";

class TrackingService {
	getTracking = (data) => {
		return ApiExec(data, "tracking/get", "POST")
			.then(function (res) {
				return res;
			})
			.then((res) => {
				return res;
			})
			.catch((e) => {
				return e;
			});
	};
}

const events = new TrackingService();
export default events;
