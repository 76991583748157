import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QRCode } from "react-qrcode-logo";
import Swal from "sweetalert2";

// Material UI
import {
	Button,
	Box,
	Typography,
	Card,
	CardMedia,
	CardContent,
	Container,
	Icon,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

// Componentes
import AdminLayout from "@components/MainPage/AdminLayout";
import CardIconColor from "@components/Card/CardIconColor";

// Utils, Servicios y Middleware
import { URL_PUBLICA_FRONT, ClipboardCopy, getVars } from "@utils/global";
import middleware from "@middlewares/middleware";

const Welcome = () => {
	const navigate = useNavigate();
	const { user, ruta_rg, casilla_rc } = getVars("Token");
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	const username = `${user.Nombre} ${user.Paterno} ${user.Materno}`.split(" ");
	let delayNumber = 0;

	const [shortUrl, setShortUrl] = useState("");
	const [totalesAvance, setTotalesAvance] = useState({
		TotalWeb: 0,
		TotalGuest: 0,
	});
	const [fechaFin, setFechaFin] = useState();
	const [loading, setLoading] = useState(true);
	const [timeRemaining, setTimeRemaining] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
	});
	const [message, setMessage] = useState(``);
	const [messageLink, setMessageLink] = useState(``);

	return (
		<AdminLayout>
			<style>{` @keyframes bounce {
                0%,100%{ transform:translate(0); }
                25%{ transform:rotateX(20deg) translateY(2px) rotate(-3deg); }
                50%{ transform:translateY(-10px) rotate(3deg) scale(1.05);  }
              }`}</style>
			<Container
				component="main"
				sx={{
					"&:hover .span-word": { animation: "bounce .6s" },
				}} /* maxWidth="lg" */
			>
				<Grid2 container spacing={1}>
					{user.linkToShare !== 0 ? (
						<Grid2 xs={12} md={5} lg={4}>
							<Card
								className="card-primary"
								sx={{
									textAlign: "center",
									minHeight: "530px",
									height: "100%",
									mb: "0 !important",
								}}
							>
								<CardContent>
									<CardMedia sx={{ overflow: "auto" }}>
										<QRCode value={shortUrl} />
									</CardMedia>

									<Grid2 container rowSpacing={1} columnSpacing={1}>
										<Grid2 xs={12}>
											<Typography
												variant="h5"
												component="div"
												align="center"
												color="#303F9F"
												gutterBottom
											>
												¡Hola!{" "}
												{username.map((item, index) => (
													<span
														key={index}
														style={{ display: "inline-block", marginRight: 8 }}
													>
														{item.split("").map((word, i) => (
															<span
																className="span-word"
																key={i}
																style={{
																	display: "inline-block",
																	animationDelay: `${delayNumber++ * 0.05}s`,
																}}
															>
																{word}
															</span>
														))}
													</span>
												))}
											</Typography>
										</Grid2>
										<Grid2 xs={12}>
											<Typography
												variant="body1"
												align="center"
												sx={{ marginBottom: "20px", whiteSpace: "pre-wrap" }}
											>
												{message}
											</Typography>
										</Grid2>
										<Grid2 xs={12}>
											<Button
												variant="text"
												href={shortUrl}
												target="_blank"
												size="small"
												fullWidth
												sx={{ whiteSpace: "pre-line" }}
											>
												{shortUrl}
											</Button>
										</Grid2>
										<Grid2 xs={12}>
											<ClipboardCopy copyText={messageLink} />
										</Grid2>
									</Grid2>
								</CardContent>
							</Card>
						</Grid2>
					) : (
						<Grid2 xs={12} md={12} lg={12}>
							<Typography variant="h5" fontWeight={700}>
								¡Hola!{" "}
								{username.map((item, index) => (
									<span
										key={index}
										style={{ display: "inline-block", marginRight: 8 }}
									>
										{item.split("").map((word, i) => (
											<span
												className="span-word"
												key={i}
												style={{
													display: "inline-block",
													animationDelay: `${delayNumber++ * 0.05}s`,
												}}
											>
												{word}
											</span>
										))}
									</span>
								))}
							</Typography>
						</Grid2>
					)}

					{/* <Grid2
						container
						xs={12}
						md={user.linkToShare !== 0 ? 7 : 12}
						lg={user.linkToShare !== 0 ? 8 : 12}
						spacing={1}
					>
						<Grid2 xs={12} sm={6} md={6} lg={4}>
							<CardIconColor
								title="Invitados"
								total={totalesAvance.TotalGuest}
								color="info"
								icon={"schema"}
								sx={{ minHeight: "215px", height: "100%" }}
								loading={loading}
							/>
						</Grid2>

						<Grid2 xs={12} sm={6} md={6} lg={4}>
							<CardIconColor
								title="Compromisos"
								total={totalesAvance.TotalWeb}
								color="success"
								icon={"schema"}
								sx={{ minHeight: "215px", height: "100%" }}
								loading={loading}
							/>
						</Grid2>

						<Grid2 xs={12} md={12} lg={4}>
							<CardIconColor
								title={`${timeRemaining.hours} horas, ${timeRemaining.minutes} minutos, ${timeRemaining.seconds} segundos`}
								total={timeRemaining.days}
								totalTextR="días"
								color="warning"
								icon={"schema"}
								sx={{ minHeight: "215px", height: "100%" }}
							/>
						</Grid2>
					</Grid2> */}
				</Grid2>
			</Container>
		</AdminLayout>
	);
};

export default React.memo(Welcome);
