import { Public } from "@middlewares";
import Encuesta from "../../pages/Encuestas/Encuesta.jsx";

const EncuestasRoutes = [
	{
		path: "/encuesta",
		element: (
			<Public>
				<Encuesta />
			</Public>
		),
	},
];

export default EncuestasRoutes;
